import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import ImageCropper from '../components/ImageCropper';

function EditProfileTab({ session, athleteProfile, setAthleteProfile, userID }) {
    const [profilePicture, setProfilePicture] = useState(null);
    const [coverPictures, setCoverPictures] = useState([]);
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState(null);
    const [cropperType, setCropperType] = useState(null);
    const [cropperIndex, setCropperIndex] = useState(null);

    useEffect(() => {
        if (athleteProfile.profile_pic) {
            setProfilePicture(athleteProfile.profile_pic);
        }
        if (athleteProfile.cover_pics) {
            setCoverPictures(Array.isArray(athleteProfile.cover_pics) ? athleteProfile.cover_pics : []);
        }
    }, [athleteProfile]);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setAthleteProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const addInterest = () => {
        if (athleteProfile.interests == null) {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                interests: [""]
            }));
        }
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: [...prevProfile.interests, ""]
        }));
    };

    const removeInterest = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: prevProfile.interests.filter((_, i) => i !== index)
        }));
    };

    const handleInterestChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newInterests = [...prevProfile.interests];
            newInterests[index] = value;
            return {
                ...prevProfile,
                interests: newInterests
            };
        });
    };

    const addAchievement = () => {
        if (athleteProfile.achievements == null) {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                achievements: [""]
            }));
        }
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: [...prevProfile.achievements, ""]
        }));
    };

    const removeAchievement = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: prevProfile.achievements.filter((_, i) => i !== index)
        }));
    };

    const handleAchievementChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newAchievements = [...prevProfile.achievements];
            newAchievements[index] = value;
            return {
                ...prevProfile,
                achievements: newAchievements
            };
        });
    };

    const handleProfileSubmit = async (event) => {
        event.preventDefault();
        const confirmation = window.confirm("Are you sure you want to save these changes?");
        if (confirmation) {
            try {
                let newProfilePicUrl = athleteProfile.profile_pic;
                if (profilePicture !== athleteProfile.profile_pic) {
                    newProfilePicUrl = await uploadImageToSupabase(profilePicture, 'profilePicture');
                }

                let newCoverPicUrls = [];
                for (let i = 0; i < coverPictures.length; i++) {
                    if (coverPictures[i] !== athleteProfile.cover_pics[i]) {
                        const newUrl = await uploadImageToSupabase(coverPictures[i], 'coverPictures');
                        newCoverPicUrls.push(newUrl);
                    } else {
                        newCoverPicUrls.push(coverPictures[i]);
                    }
                }

                const updatedProfile = {
                    ...athleteProfile,
                    profile_pic: newProfilePicUrl,
                    cover_pics: newCoverPicUrls
                };

                const { error: updateError } = await supabase
                    .from('athletes')
                    .update(updatedProfile)
                    .eq('uuid', userID);

                if (updateError) {
                    console.error('Error updating profile:', updateError);
                } else {
                    alert('Profile updated successfully!');
                    setAthleteProfile(updatedProfile);
                }
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        } else {
            alert('Profile update canceled.');
        }
    };

    const handleImageUpload = (event, type, index = null) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCropperImage(e.target.result);
                setCropperType(type);
                setCropperIndex(index);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCropComplete = async (croppedImage) => {
        setShowCropper(false);
        if (cropperType === 'profile') {
            setProfilePicture(croppedImage);
        } else if (cropperType === 'cover') {
            const newCoverPictures = [...coverPictures];
            newCoverPictures[cropperIndex] = croppedImage;
            setCoverPictures(newCoverPictures);
        }
    };

    const uploadImageToSupabase = async (image, folder) => {
        const username = athleteProfile.username;
        const fileName = `${Date.now()}.jpg`;
        const filePath = `${username}/${folder}/${fileName}`;
        console.log(filePath);

        const { data, error } = await supabase.storage
            .from('userMedia')
            .upload(filePath, image);

        if (error) {
            console.error('Error uploading image:', error);
            return null;
        }

        const { data: { publicUrl } } = supabase.storage
            .from('userMedia')
            .getPublicUrl(filePath);

        return publicUrl;
    };

    return (
        <div className="dashboard-tab-content">
            <Form>
                <div className="dashboard-faq-title">Public Information</div>
                <div className='dashboard-explination'>This information is displayed on your profile</div>
                <Form.Group className="mb-3">
                    <Form.Label>Photos</Form.Label>
                    <div className='dashboard-explination'>Contact the Infield team to change your profile or cover photos</div>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label>Profile Picture</Form.Label>
                    <div className="profile-picture-container">
                        {profilePicture && <img src={profilePicture} alt="Profile" className="profile-picture-preview" />}
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, 'profilePicture')}
                            style={{ display: 'none' }}
                            id="profile-picture-upload"
                        />
                        <label htmlFor="profile-picture-upload" className="btn btn-primary">
                            Change Profile Picture
                        </label>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Cover Photos</Form.Label>
                    <div className="cover-photos-container">
                        {[0, 1, 2].map((index) => (
                            <div key={index} className="cover-photo-item">
                                {coverPictures[index] && (
                                    <img 
                                        src={coverPictures[index]} 
                                        alt={`Cover ${index + 1}`} 
                                        className="cover-photo-preview" 
                                    />
                                )}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e, 'cover', index)}
                                    style={{ display: 'none' }}
                                    id={`cover-photo-upload-${index}`}
                                />
                                <label htmlFor={`cover-photo-upload-${index}`} className="btn btn-secondary">
                                    {coverPictures[index] ? 'Change Cover Photo' : 'Add Cover Photo'}
                                </label>
                            </div>
                        ))}
                    </div>
                </Form.Group> */}
                <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="firstName"
                        value={athleteProfile.firstName}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="lastName"
                        value={athleteProfile.lastName}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Sport</Form.Label>
                    <Form.Control
                        type="text"
                        name="sport"
                        value={athleteProfile.sport}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                        type="text"
                        name="position"
                        value={athleteProfile.position}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Achievement highlights<div className='text-color-gray'></div></Form.Label>
                    {athleteProfile.achievements.map((achievement, index) => (
                        <div key={index} className="dashboard-expertise-entry" style={{marginBottom: "10px"}}>
                            <Form.Control
                                type="text"
                                value={achievement}
                                onChange={(e) => handleAchievementChange(index, e.target.value)}
                                placeholder="E.g. a stat, championship, record, or affiliation"
                            />
                        </div>
                    ))}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="5"
                        name="bio"
                        value={athleteProfile.bio}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Areas of insight <div className='text-color-gray'>(eight maximum)</div></Form.Label>
                    {athleteProfile.interests.map((interest, index) => (
                        <div key={index} className="dashboard-expertise-entry">
                            <Form.Control
                                type="text"
                                value={interest}
                                onChange={(e) => handleInterestChange(index, e.target.value)}
                                placeholder="e.g. Strength training, College Recruiting"
                            />
                            {athleteProfile.interests.length > 1 &&
                                <div className="dashboard-expertise-remove" onClick={() => removeInterest(index)}>
                                    × Remove
                                </div>
                            }
                        </div>
                    ))}
                    {athleteProfile.achievements.length < 8  &&
                        <div className="dashboard-expertise-add" onClick={addInterest}>
                            + Add an insight
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Instagram <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="instagram"
                        value={athleteProfile.instagram}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>TikTok <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="tiktok"
                        value={athleteProfile.tiktok}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>X/Twitter <div className='text-color-gray'>(exclude the "@" symbol)</div></Form.Label>
                    <Form.Control
                        type="text"
                        name="x"
                        value={athleteProfile.x}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <div className="dashboard-faq-title">Account Information</div>
                <Form.Group className="mb-3">
                    <Form.Label>No-go topics</Form.Label>
                    <div className='dashboard-explination'>Let our team know if there are any question topics that you don't wish to see.</div>
                    <Form.Control
                        as="textarea"
                        rows="3"
                        name="no_go_topics"
                        value={athleteProfile.no_go_topics}
                        onChange={handleProfileChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Reset Password</Form.Label>
                    <div className='roster-text'>
                        <a className='contact-redirect-link' href='/passwordreset'><u>Click here to reset your password.</u></a>
                    </div>
                </Form.Group>
                <button className="dashboard-profile-changes" onClick={handleProfileSubmit}>
                    Save changes
                </button>
            </Form>
            {showCropper && (
                <ImageCropper
                    image={cropperImage}
                    onCropComplete={handleCropComplete}
                    aspect={cropperType === 'profile' ? 1 : 16 / 9}
                />
            )}
        </div>
    );
}

export default EditProfileTab;