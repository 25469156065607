import React, { useState } from 'react';

function ConversationsTab({ session, athleteProfile}) {

    return (
        <div className="dashboard-tab-content">
            <div className="dashboard-no-requests-message">
                <em>No conversation yet! Respond to requests to start a conversation.</em>
            </div>
        </div>
    );
}

export default ConversationsTab;