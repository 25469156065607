import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../SupabaseClient';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IoIosStar } from "react-icons/io";
import ConfirmationAlert from '../components/ConfirmationAlert';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import { BiSolidMessageRoundedAdd } from "react-icons/bi";

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51Q5LzRKP6FEYUboM7Blb6M2SQdbMitByNwZBWm1B4WDbJfldHoVpPnNzPo5fOQkGvpM8WubFob1IK7quHdIJeR7S00suN7u7gR'); // Switch to live key for production !

const ViewPage = () => {
    const { id } = useParams();
    const [response, setResponse] = useState(null);
    const [submission, setSubmission] = useState(null);
    const [athleteID, setAthleteID] = useState(null);
    const [athleteData, setAthleteData] = useState(null);
    const [rating, setRating] = useState(5.0);
    const [feedback, setFeedback] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [wantsToRate, setWantsToRate] = useState(true); // New state variable
    const [responseFeedback, setResponseFeedback] = useState(''); // New state variable
    const [selectedDonation, setSelectedDonation] = useState(0);
    const [isCustomDonationActive, setIsCustomDonationActive] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [isPaymentValid, setIsPaymentValid] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);

    useEffect(() => {
        setFormSubmitted(false);
        const fetchResponse = async () => {
            const { data, error } = await supabase
                .from('responses')
                .select('*')
                .eq('id', id)
                .single();
            if (error) {
                console.error('Error fetching response:', error);
            } else {
                setAthleteID(data.athlete_ID);
                setResponse(data);
                if (data.rating == null ) {
                    setFormSubmitted(false);
                } else {
                    setFormSubmitted(true);
                    console.log("Feedback Already Submitted: ", formSubmitted);
                }
            }
        };
        fetchResponse();
    }, [id]);

    useEffect(() => {
        if (response) {
            const fetchSubmission = async () => {
                const { data, error } = await supabase
                    .from('submissions')
                    .select('*')
                    .eq('id', response.submission_ID)
                    .single();
                if (error) {
                    console.error('Error fetching submission:', error);
                } else {
                    setSubmission(data);
                }
            };
            fetchSubmission();
        }
    }, [response]);

    useEffect(() => {
        if (athleteID) {
            const fetchAthleteData = async () => {
                const { data, error } = await supabase
                    .from('athletes')
                    .select('*')
                    .eq('uuid', athleteID)
                    .single();
                if (error) {
                    console.error('Error fetching athlete data:', error);
                } else {
                    setAthleteData(data);
                }
            };
            fetchAthleteData();
        }
    }, [athleteID]);

    useEffect(() => {
        if (selectedDonation > 0) {
            axios.post('https://infield-backend.vercel.app/create-payment-intent', {
                amount: selectedDonation, // Amount in cents
                currency: 'usd'
            })
            .then(response => {
                setClientSecret(response.data.clientSecret);
            })
            .catch(error => {
                console.error('Error fetching client secret:', error);
            });
        }
    }, [selectedDonation]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Update the responses table as before
            if (wantsToRate) {
                const { error: updateError } = await supabase
                    .from('responses')
                    .update({
                        athlete_rating: rating,
                        feedback: feedback
                    })
                    .eq('id', id);

                if (updateError) {
                    console.error('Error updating response:', updateError);
                    return;
                }
                const newRating = athleteData.rating_total + rating;
                const newCount = athleteData.rating_count + 1;
                const { error: athleteUpdateError } = await supabase
                    .from('athletes')
                    .update({
                        rating_total: newRating,
                        rating_count: newCount
                    })
                    .eq('uuid', response.athlete_ID);
                if (athleteUpdateError) {
                    console.error('Error updating athlete data:', athleteUpdateError);
                    return;
                }
            } else {
                const { error: updateError } = await supabase
                    .from('responses')
                    .update({
                        feedback: feedback
                    })
                    .eq('id', id);
                if (updateError) {
                    console.error('Error updating response:', updateError);
                    return;
                }
            }

            // Insert into request-feedback table
            const { error: feedbackError } = await supabase
                .from('requester_feedback')
                .insert({
                    athlete_username: athleteData.username,
                    response_id: response.id,
                    request_id: submission.id,
                    rating: rating,
                    infield_feedback: feedback,
                    response_feedback: responseFeedback
                });

            if (feedbackError) {
                console.error('Error inserting feedback:', feedbackError);
                return;
            }

            setShowConfirmation(true);
            setFormSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const handleStarClick = (index) => {
        setRating(parseFloat((index + 1).toFixed(1)));
    };

    const toggleRating = () => {
        setWantsToRate(!wantsToRate);
    };

    const handleDonation = (amount) => {
        if (selectedDonation === amount) {
            setSelectedDonation(0);
        } else {
            setSelectedDonation(amount);
        }
        setIsCustomDonationActive(false);
    };

    const toggleCustomDonation = () => {
        setIsCustomDonationActive(!isCustomDonationActive);
        if (isCustomDonationActive) {
            setSelectedDonation(0);
        }
    };

    const handleCustomDonationChange = (event) => {
        const customAmount = parseFloat(event.target.value);
        if (customAmount >= 2) {
            setSelectedDonation(customAmount * 100);
        } else {
            setSelectedDonation(0);
        }
    };

    if (!response || !submission || !athleteData) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    } else {
        return (
            <div className='view-page-format'>
                <div className="view-form">
                    <div className="view-header">Your response from {athleteData.firstName} has arrived!</div>
                    <div className='view-response-container'>
                        <div className='view-video-col'>
                            <div className='view-video-title'>Your request</div>
                            {submission.messageType === 'text' && <div className='view-text-message' style={{padding:"5px"}}>{submission.message}</div>}
                            {submission.messageType === 'video' && (
                                <SecureVideoPlayer
                                    src={submission.video}
                                    className='admin-dashboard-video'
                                />
                            )}
                        </div>
                        <div className='view-video-col'>
                            <div className='view-video-title'>{athleteData.firstName}'s response</div>
                            <SecureVideoPlayer
                                    src={response.video_link}
                                className='admin-dashboard-video'
                            />
                        </div>
                    </div>
                    <div className='view-response-donation'>
                        <div className='view-response-title'>
                            <b>Keep the conversation going by sending another request</b>
                        </div>
                            <Button className='view-payment-button' href={`https://www.infieldconnect.co/request/${athleteData.username}`}>
                                <BiSolidMessageRoundedAdd className='home-pitch-button-icon' />
                                    New Request
                            </Button>
                    </div>
                    <div className='view-response-donation'>
                        <div className='view-response-title'>
                            <b>Send {athleteData.firstName} something for their time</b>
                        </div>
                        <div className="donation-container">
                            <div className="donation-subcontainer">
                                <button
                                    type="button"
                                    className={`donation-button ${selectedDonation === 500 ? 'selected' : ''}`}
                                    onClick={() => handleDonation(500)}
                                >
                                    $5
                                </button>
                                <button
                                    type="button"
                                    className={`donation-button ${selectedDonation === 1000 ? 'selected' : ''}`}
                                    onClick={() => handleDonation(1000)}
                                >
                                    $10
                                </button>
                                <button
                                    type="button"
                                    className={`donation-button ${isCustomDonationActive ? 'selected' : ''}`}
                                    onClick={toggleCustomDonation}
                                >
                                    Custom
                                </button>
                                {isCustomDonationActive && (
                                    <input
                                        type="number"
                                        min="1"
                                        placeholder="Enter amount"
                                        className="donation-input"
                                        onChange={handleCustomDonationChange}
                                    />
                                )}
                            </div>
                        </div>
                        {selectedDonation > 0 && clientSecret && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <PaymentForm
                                    setShowConfirmationAlert={setShowConfirmationAlert}
                                    setSelectedDonation={setSelectedDonation}
                                    isPaymentValid={isPaymentValid}
                                    setIsPaymentValid={setIsPaymentValid}
                                    paymentError={paymentError}
                                    setPaymentError={setPaymentError}
                                    isSubmitting={isSubmitting}
                                    setIsSubmitting={setIsSubmitting}
                                />
                            </Elements>
                        )}
                    </div>
                    {showConfirmationAlert && (
                        <ConfirmationAlert
                            message="Payment successfully sent!"
                            onClose={() => setShowConfirmationAlert(false)}
                        />
                    )}
                    {!formSubmitted && response.athlete_rating == null && (
                        <Form className='view-response-feedback' onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label className='view-response-title'>If you liked {athleteData.firstName}'s response, rate it!</Form.Label>
                                {wantsToRate && (
                                    <div className="view-star-rating">
                                        {[...Array(5)].map((star, index) => (
                                            <IoIosStar
                                                key={index}
                                                className={index < rating ? 'view-star selected' : 'view-star'}
                                                onClick={() => handleStarClick(index)}
                                            />
                                        ))}
                                    </div>
                                )}
                                <div onClick={toggleRating} className='view-rating-text'>
                                    {wantsToRate ? "I don't want to rate" : "Add a rating"}
                                </div>
                            </Form.Group>
                            <Form.Group style={{marginTop: "min(2vw,20px)"}}>
                                <Form.Label className='view-response-title'>What did you think about {athleteData.firstName}'s response?</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='(Optional)'
                                    className='view-response-text'
                                    rows={2}
                                    value={responseFeedback}
                                    onChange={(e) => setResponseFeedback(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group style={{marginTop: "min(2vw,20px)"}}>
                                <Form.Label className='view-response-title'>How can Infield change to improve your experience?</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder='(Optional)'
                                    className='view-response-text'
                                    rows={2}
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                            </Form.Group>

                            <button type="submit" className='view-feedback-button'>Submit Feedback</button>
                        </Form>
                    )}
                    {showConfirmation && (
                        <ConfirmationAlert
                            message="Thank you for your feedback!"
                            onClose={() => setShowConfirmation(false)}
                        />
                    )}
                </div>
            </div>
        );
    }
};

const PaymentForm = ({ setShowConfirmationAlert, setSelectedDonation, isPaymentValid, setIsPaymentValid, paymentError, setPaymentError, isSubmitting, setIsSubmitting }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmitPayment = async () => {
        setIsSubmitting(true);

        if (!stripe || !elements) {
            console.error('Stripe or elements not initialized');
            setIsSubmitting(false);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: 'if_required',
        });

        if (error) {
            console.error('Payment verification failed:', error);
            setPaymentError(error.message || 'An unknown error occurred during payment verification.');
            setIsSubmitting(false);
            return;
        }

        setShowConfirmationAlert(true);
        setSelectedDonation(0);
        setIsSubmitting(false);
    };

    return (
        <>
            <PaymentElement
                onChange={(event) => {
                    setIsPaymentValid(event.complete);
                    if (event.error) {
                        setPaymentError(event.error.message);
                    } else {
                        setPaymentError('');
                    }
                }}
            />
            {paymentError && <div className="error-message">{paymentError}</div>}
            <button
                className="view-feedback-button"
                onClick={handleSubmitPayment}
                disabled={!isPaymentValid || isSubmitting}
            >
                {isSubmitting ? 'Processing...' : 'Submit Payment'}
            </button>
        </>
    );
};

export default ViewPage;