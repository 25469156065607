import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../SupabaseClient';
import { Button } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag";
import { BsLightning } from "react-icons/bs";
import ConfirmationModal from '../components/ConfirmationModal';
import ConfirmationAlert from '../components/ConfirmationAlert';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import axios from 'axios';

function NewRequestsTab({ session, athleteProfile}) {
    const [submissions, setSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: null,
        showTextarea: false,
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [rejectionNote, setRejectionNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);

    // Fetching submission data -----------------------------------------------------------------------------------

    useEffect(() => {
        const fetchSubmissions = async () => {
            const { data: user, error: authError } = await supabase.auth.getUser();
            const userUID = user.user.id;
            const { data, error } = await supabase
                .from('submissions')
                .select('*')
                .eq('athleteID', userUID)
                .eq('status', 'approved');
            if (error) {
                console.error('Error fetching submissions:', error);
            } else {
                setSubmissions(data);
            }
        };
        if (session) {
            fetchSubmissions();
        }
    }, [session]);

    const handleFileChange = (e) => {
        setVideoFile(e.target.files[0]);
        const file = e.target.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            videoElement.src = videoUrl;
            videoElement.onloadedmetadata = () => {
                if (videoElement.duration > 41) {
                    alert("The selected video is too long. Please select a video that is 40 seconds or less.");
                    clearVideo();
                    return;
                } else {
                    setVideoThumbnail(videoUrl);
                }
            };
        }
    };

    const clearVideo = () => {
        setVideoThumbnail(null);
        setVideoFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleContainerClick = (submission) => {
        if (selectedSubmission && selectedSubmission.id === submission.id) {
            setSelectedSubmission(null);
            clearVideo();
        } else {
            setSelectedSubmission(submission);
            clearVideo();
        }
    };

    const openModal = (config) => {
        setModalConfig(config);
        setIsModalOpen(true);
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    const handleSubmitButton = () => {
        if (selectedSubmission) {
            openModal({
                title: 'Confirm request response',
                message: `Confirm your response to ${selectedSubmission.firstName}\'s request.`,
                confirmText: 'Send response',
                cancelText: 'Cancel',
                placeholderText: '',
                onConfirm: handleSubmit,
                showTextarea: false,
            });
            setRejectionNote(rejectionNote);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!videoFile || !selectedSubmission) return;
        setIsSubmitting(true);
        try {
            setIsModalOpen(false);
            const videoName = `${selectedSubmission.id}-${Date.now()}.mp4`;
            const { error: uploadError } = await supabase.storage
                .from('videos')
                .upload(`responses/${videoName}`, videoFile);
            if (uploadError) {
                console.error('Error uploading video:', uploadError);
                return;
            }
            const { data } = supabase
                .storage
                .from('videos')
                .getPublicUrl(`responses/${videoName}`);
            const responseData = {
                requester_email: selectedSubmission.email,
                requester_name: selectedSubmission.firstName,
                submission_ID: selectedSubmission.id,
                athlete_ID: selectedSubmission.athleteID,
                athlete_name: athleteProfile.firstName + ' ' + athleteProfile.lastName,
                video_link: data.publicUrl,
            };
            const { error: dbError } = await supabase
                .from('responses')
                .insert([responseData]);
            if (dbError) {
                console.error('Error saving to database:', dbError);
                return;
            }
            const responseID = await fetchResponseID();
            sendEmail(responseData, responseID);
            setAlertMessage('Your response has been sent');
            setShowAlert(true);
            const { error: updateError } = await supabase
                .from('submissions')
                .update({ status: 'completed' })
                .eq('id', selectedSubmission.id);
            if (updateError) {
                console.error('Error updating submission status:', updateError);
            } else {
                setSubmissions((prev) => prev.filter((submission) => submission.id !== selectedSubmission.id));
                setSelectedSubmission(null);
            }
        } catch (error) {
            console.error('Error submitting video:', error);
            setAlertMessage('Submission failed. Please try again.');
            setShowAlert(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchResponseID = async () => {
        const { data, error } = await supabase
            .from('responses')
            .select('*')
            .eq('submission_ID', selectedSubmission.id)
            .order('created_at', { ascending: false })
            .limit(1)
            .single();
        if (error) {
            console.error('Error fetching response ID:', error);
        } else {
            return data.id;
        }
    };

    const sendEmail = async (responseData, responseID) => {
        const emailData = {
            subject: selectedSubmission.subject,
            reply_to: 'info@infieldconnect.co',
            requester_email: responseData.requester_email,
            requester_name: responseData.requester_name,
            athlete_name: responseData.athlete_name,
            video_link: `https://infieldconnect.co/view/${responseID}`,
        };
        try {
            const response = await axios.post('https://infield-backend.vercel.app/response-notification', emailData);
            console.log(response.data);
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    const handleRejectionButton = () => {
        if (!selectedSubmission) return;
        openModal({
            title: 'Reject Request',
            message: 'Are you sure you want to reject this request? If so, tell our team why so we know not to show you these kinds of requests in the future.',
            confirmText: 'Reject',
            cancelText: 'Cancel',
            placeholderText: 'Reason for rejection (optional)',
            onConfirm: handleReject,
            showTextarea: true,
        });
        setRejectionNote(rejectionNote);
    };

    const handleReject = async (event) => {
        event.preventDefault(event);
        try {
            setIsModalOpen(false);
            const { data, error } = await supabase
                .from('submissions')
                .update({ status: "athlete rejected", rejection_note: rejectionNote })
                .eq('id', selectedSubmission.id);
            if (error) {
                console.error('Error updating request status:', error);
            } else {
                setSubmissions((prev) => prev.filter((submission) => submission.id !== selectedSubmission.id));
                setSelectedSubmission(null);
            }
        } catch (error) {
            console.error('Error rejecting request:', error);
        }
        setAlertMessage('The request has been rejected');
        setShowAlert(true);
    };

    return (
        <div className="dashboard-stack">
            {showAlert && <ConfirmationAlert message={alertMessage} onClose={closeAlert} />}
            {submissions.length > 0 ? (
                submissions.map((submission) => (
                    <div
                        key={submission.id}
                        className={`dashboard-entry ${selectedSubmission && selectedSubmission.id === submission.id ? 'expanded' : ''}`}
                    >
                        <div className={`dashboard-entry-preview${selectedSubmission && selectedSubmission.id === submission.id ? `-expanded-${selectedSubmission.messageType}` : ''}`} onClick={() => handleContainerClick(submission)}>
                            <div>
                                <div className='dashboard-entry-subject'>{submission.subject}</div>
                                <div className='dashboard-entry-preview-info'>
                                    <div className={`dashboard-entry-type-${submission.messageType}`}>
                                        {submission.messageType === 'video' ? 'Video' : 'Text'}
                                    </div>
                                    <div><div className='text-color-gray'>from </div><b>{submission.firstName} {submission.lastName}</b> <ReactCountryFlag countryCode={submission.countryValue} svg /></div>
                                </div>
                            </div>
                            <div className="dashboard-date">{new Date(submission.createdAt + 'Z').toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}</div>
                        </div>
                        {selectedSubmission && selectedSubmission.id === submission.id && (
                            <div className="dashboard-entry-container">
                                <div className='dashboard-entry-col-details'>
                                    <div className='dashboard-entry-header'>Request details:</div>
                                    <div className='dashboard-entry-generic-text'><b>Sent: </b>
                                        {new Date(submission.createdAt + 'Z').toLocaleString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                            year: 'numeric',
                                        })}
                                    </div>
                                    <div className='dashboard-entry-details'><b>From:</b> {submission.city}, {submission.countryName} <ReactCountryFlag countryCode={submission.countryValue} svg /></div>
                                    <div className='dashboard-entry-details'><b>Message:</b></div>
                                    {submission.messageType === 'text' && <div className='dashboard-entry-details'>{submission.message}</div>}
                                    {submission.messageType === 'video' && (
                                        <SecureVideoPlayer
                                            src={selectedSubmission.video}
                                            className='admin-dashboard-video'
                                        />
                                    )}
                                </div>
                                <div className='dashboard-entry-col-response'>
                                    <div className='dashboard-entry-header'>Your response:</div>
                                    <div className="request-video-upload">
                                        {videoThumbnail && (
                                            <SecureVideoPlayer
                                                src={videoThumbnail}
                                                className='admin-dashboard-video'
                                            />
                                        )}
                                        <div className='dashboard-button-container'>
                                            {!videoThumbnail ?
                                                (<div>
                                                    <input
                                                        type="file"
                                                        name="video"
                                                        required
                                                        accept="video/*"
                                                        className="dashboard-video-button"
                                                        id="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <label htmlFor="file">Upload a response</label>
                                                </div>)
                                                : (<div>
                                                    <input
                                                        type="file"
                                                        name="video"
                                                        required
                                                        accept="video/*"
                                                        className="dashboard-video-button-change"
                                                        id="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <label htmlFor="file">Change video</label>
                                                </div>)
                                            }
                                            {videoFile &&
                                                <div>
                                                    <button onClick={handleSubmitButton} disabled={!videoFile || isSubmitting} className='dashboard-button-submit'>
                                                        {isSubmitting ? (
                                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                            Submitting
                                                            <div className="loader-button" style={{ marginLeft: '10px' }}>
                                                            </div>
                                                        </div>
                                                        ) : (
                                                        "Submit response"
                                                        )}
                                                    </button>
                                                    <ConfirmationModal
                                                        isOpen={isModalOpen}
                                                        title={modalConfig.title}
                                                        message={modalConfig.message}
                                                        confirmText={modalConfig.confirmText}
                                                        cancelText={modalConfig.cancelText}
                                                        placeholderText={modalConfig.placeholderText}
                                                        showTextarea={modalConfig.showTextarea}
                                                        onConfirm={modalConfig.onConfirm}
                                                        onCancel={() => setIsModalOpen(false)}
                                                    />
                                                </div>
                                            }
                                            {showAlert && <ConfirmationAlert message={alertMessage} onClose={closeAlert} />}
                                        </div>
                                    </div>
                                    <div className='dashboard-entry-generic-text'><b>Or,</b></div>
                                    <button onClick={handleRejectionButton} disabled={!selectedSubmission} className='dashboard-button-reject'>
                                        Reject request
                                    </button>
                                    <div className='dashboard-entry-instructions text-color-gray'><b>Note:</b> Any requests that haven't been responded to within 3 days of submission will automatically be rejected.</div>
                                    <ConfirmationModal
                                        isOpen={isModalOpen}
                                        title={modalConfig.title}
                                        message={modalConfig.message}
                                        confirmText={modalConfig.confirmText}
                                        cancelText={modalConfig.cancelText}
                                        placeholderText={modalConfig.placeholderText}
                                        showTextarea={modalConfig.showTextarea}
                                        onConfirm={modalConfig.onConfirm}
                                        onCancel={() => setIsModalOpen(false)}
                                        onTextareaChange={(value) => setRejectionNote(value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className="dashboard-no-requests-message">
                    <em>You have no new requests</em>
                </div>
            )}
        </div>
    );
}

export default NewRequestsTab;