import React, { useEffect, useState } from 'react';
import { supabase } from '../SupabaseClient';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RiFeedbackFill } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import NewRequestsTab from '../components/NewRequestsTab';
import ConversationsTab from '../components/ConversationsTab';
import EditProfileTab from '../components/EditProfileTab';
import HelpTab from '../components/HelpTab';
import { BsLightning } from "react-icons/bs";
import { LuHelpCircle } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";

function AthleteDashboardPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [userID, setUserID] = useState('');
    const [newRequestsCount, setNewRequestsCount] = useState(0);
    const [completedRequestsCount, setCompletedRequestsCount] = useState(0);
    const [totalRequestsCount, setTotalRequestsCount] = useState(0);
    const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false);
    const [feedbackInputValue, setFeedbackInputValue] = useState('');
    const [rejectionNote, setRejectionNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [session, setSession] = useState(null);
    const [activeTab, setActiveTab] = useState('inbox');
    const [loading, setLoading] = useState(false);
    const [athleteProfile, setAthleteProfile] = useState({
        firstName: '',
        lastName: '',
        sport: '',
        position: '',
        achievements: [],
        no_go_topics: "",
        bio: '',
        interests: [],
        instagram: '',
        tiktok: '',
        x: '',
        role: '',
        email: '',
    });

    const handleFeedbackExpand = () => {
        setIsFeedbackExpanded(true);
    };

    const handleFeedbackClose = () => {
        setIsFeedbackExpanded(false);
        setFeedbackInputValue('');
    };

    const handleFeedbackSubmit = async () => {
        try {
            const { data, error } = await supabase
                .from('user_feedback')
                .insert([
                    { content: feedbackInputValue, username: username, userID: userID }
                ]);

            if (error) {
                throw error;
            }
            console.log(feedbackInputValue);
            setIsFeedbackExpanded(false);
            setFeedbackInputValue('');
        } catch (error) {
            console.error('Error submitting feedback:', error.message);
        }
    };

    useEffect(() => {
        setLoading(true);
        const fetchSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setSession(session);
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
        return () => subscription.unsubscribe();
    }, []);

    const fetchRequestCounts = async (userID) => {
        try {
            const { count: newCount } = await supabase
                .from('submissions')
                .select('*', { count: 'exact' })
                .eq('athleteID', userID)
                .eq('status', 'approved');
            const { count: completedCount } = await supabase
                .from('submissions')
                .select('*', { count: 'exact' })
                .eq('athleteID', userID)
                .eq('status', 'completed');
            const { count: totalCount } = await supabase
                .from('submissions')
                .select('*', { count: 'exact' })
                .eq('athleteID', userID);
            setNewRequestsCount(newCount || 0);
            setCompletedRequestsCount(completedCount || 0);
            setTotalRequestsCount(totalCount || 0);
        } catch (error) {
            console.error('Error fetching request counts:', error);
        }
        setLoading(false);
    };

    const fetchAthleteProfile = async () => {
        const { data: user, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
            console.error('Error fetching user:', authError);
            return;
        }
        const userID = user.user.id;
        const { data, error } = await supabase
            .from('athletes')
            .select('username, firstName, lastName, sport, position, achievements, bio, interests, instagram, tiktok, x, profile_pic, no_go_topics, role, email')
            .eq('uuid', userID)
            .single();
        if (error) {
            console.error('Error fetching athlete profile:', error);
        } else {
            setUsername(data.username);
            setAthleteProfile(data);
            setUserID(userID);
        }
    };

    useEffect(() => {
        if (session) {
            fetchAthleteProfile();
            fetchRequestCounts(userID);
        }
    }, [session]);

    if (!session) {
        return (
            <div className="dashboard-page-format">
                <div className="contact-form">
                    <div className="contact-title">Sign in</div>
                    <Auth
                        supabaseClient={supabase}
                        appearance={{ theme: ThemeSupa }}
                        providers={[]}
                        showLinks={false}
                        localization={{
                            variables: {
                                sign_in: {
                                    email_label: 'Your email address',
                                    password_label: 'Your password',
                                },
                                forgotten_password: {
                                    button_label: 'Forgot password?',
                                },
                            },
                        }}
                    />
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    } else {
        return (
            <div className="dashboard-page-format">
                <div className={`floating-button ${isFeedbackExpanded ? 'expanded' : ''}`} onClick={!isFeedbackExpanded ? handleFeedbackExpand : null}>
                    {!isFeedbackExpanded ? (
                        <div>
                            <div style={{display: "inline-block"}}>Need something?</div>
                            <RiFeedbackFill className='floating-button-icon'/>
                        </div>
                    ) : (
                        <div className="floating-button-expanded-content">
                            <div className="floating-button-title">Contact our team</div>
                            <textarea
                                value={feedbackInputValue}
                                onChange={(e) => setFeedbackInputValue(e.target.value)}
                                placeholder='Let us know about any issues or suggestions you have.'
                            />
                            <button onClick={handleFeedbackSubmit}>Submit</button>
                            <button onClick={handleFeedbackClose}>Close</button>
                        </div>
                    )}
                </div>
                <div className="dashboard-form">
                <div className='dashboard-header-container'>
        <div className='dashboard-header-profile'>
            <img className="dashboard-profile-picture" src={athleteProfile.profile_pic} />
            <div className='dashboard-name'>{athleteProfile.firstName} {athleteProfile.lastName}</div>
            <Button className='dashboard-profile-button' href={`/athletes/${athleteProfile.username}`}>View profile</Button>
        </div>
        <div className='dashboard-header-stats'>
            <div className='dashboard-header-stat'>
                <div className='dashboard-header-stat-number'>{newRequestsCount}</div>
                <div>New requests</div>
            </div>
            <div className='dashboard-header-stat'>
                <div className='dashboard-header-stat-number'>{completedRequestsCount}</div>
                <div>Fulfilled requests</div>
            </div>
            <div className='dashboard-header-stat'>
                <div className='dashboard-header-stat-number'>$ 0.00</div>
                <div>Total earnings</div>
            </div>
        </div>
    </div>
    <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className='dashboard-tab-title'>
        <Tab eventKey="inbox"
            title={<div style={{ display: 'flex', alignItems: 'center' }}><BsLightning style={{marginRight: '8px' }}/> Requests</div>}
        >
            <NewRequestsTab
                session={session}
                athleteProfile={athleteProfile}
            />
        </Tab>
        {/* <Tab eventKey="conversations"
            title={<div style={{ display: 'flex', alignItems: 'center' }}><TbMessages style={{marginRight: '8px' }}/> Conversations</div>}
        >
            <ConversationsTab
                session={session}
                athleteProfile={athleteProfile}
            />
        </Tab> */}
        <Tab eventKey="profile"
            title={<div style={{ display: 'flex', alignItems: 'center' }}><CiEdit style={{marginRight: '8px' }}/> Profile</div>}
        >
            <EditProfileTab
                session={session}
                athleteProfile={athleteProfile}
                setAthleteProfile={setAthleteProfile}
                userID={userID}
            />
        </Tab>
        <Tab eventKey="help"
            title={<div style={{ display: 'flex', alignItems: 'center' }}><LuHelpCircle style={{marginRight: '8px' }}/> Help</div>}
        >
            <HelpTab athleteProfile={athleteProfile} />
        </Tab>
    </Tabs>
</div>
</div>
);
}
}

export default AthleteDashboardPage;