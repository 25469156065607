import { useParams, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { supabase } from '../SupabaseClient';
import { MdOutlineStars } from "react-icons/md";
import { RxLightningBolt } from "react-icons/rx";
import { IoIosStar } from "react-icons/io"; // Import the star icon
import instagram_logo from '../instagram_logo.svg';
import tiktok_logo from '../tiktok_logo.svg';
import x_logo from '../x_logo.svg';

function AthleteProfilePage() {
    const { username } = useParams(); // Get the username from the URL
    const [athlete, setAthlete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [ratingScore, setRatingScore] = useState(0); // State for rating score

    useEffect(() => {
        const fetchAthleteData = async () => {
            try {
                const { data, error } = await supabase
                    .from('athletes')
                    .select('*')
                    .eq('username', username)
                    .single(); // Fetch a single athlete based on the username

                if (error) throw error;
                setAthlete(data);
                if (data.rating_count > 0) {
                    setRatingScore(data.rating_total / data.rating_count); // Calculate rating score
                }
            } catch (error) {
                setError("Sorry, we can't seem to find that athlete in our network.");
            } finally {
                setLoading(false);
            }
        };
        fetchAthleteData();
    }, [username]);

    useEffect(() => {
        if (athlete && athlete.cover_pics.length > 0) {
            const interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % athlete.cover_pics.length);
            }, 5000); // Change image every 5 seconds
            return () => clearInterval(interval);
        }
    }, [athlete]);

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    if (error || !athlete) {
        return <div>{error}</div>;
    }

    if (!athlete) {
        return <div>Sorry, we can't seem to find that athlete in our network</div>;
    }
    const { firstName, lastName, profile_pic, bio, sport, position, achievements, interests, instagram, tiktok, x, cover_pics } = athlete;

    const renderStars = (score) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            const fillPercentage = Math.min(Math.max(score - (i - 1), 0), 1) * 100; // Fill percentage for each star
            stars.push(
                <div className="profile-star" key={i}>
                    {/* Gray star (background) */}
                    <IoIosStar className="profile-star-gray"/>
                    {/* Gold star (overlay) */}
                    <IoIosStar className="profile-star-color"
                        style={{
                            clipPath: `inset(0 ${100 - fillPercentage}% 0 0)`
                        }}
                    />
                </div>
            );
        }
        return stars;
    };

    return (
        <div className='profile-wrapper'>
            <div className="profile-cover-section">
                <div className='profile-cover'>
                    {cover_pics && cover_pics.length > 0 && cover_pics.map((pic, index) => (
                        <img
                            key={index}
                            className={`profile-cover-image ${index === currentImageIndex ? 'active' : ''}`}
                            src={pic}
                            alt="Cover"
                        />
                    ))}
                    <img className="profile-picture" src={profile_pic} alt={`${firstName} ${lastName}`} />
                </div>
                <div className="profile-name-cover">
                    <div className="profile-name-container">
                        <div className="profile-name"><b>{firstName} {lastName}</b></div>
                        {position !== "" ?
                            <div className="profile-sport">{position}, {sport}</div>
                            :
                            <div className="profile-sport">{sport}</div>
                        }
                        <div className="profile-achievement-container">
                            {achievements.map((achievement, index) => (
                                <div key={index} className='profile-achievement'>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <MdOutlineStars className="profile-achievement-icon" />
                                    </div>
                                    <div>
                                        {achievement}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="profile-socialmedia-container">
                            {instagram !== "" && <a className='profile-socialmedia' href={`https://instagram.com/${instagram}`} target="_blank"><img className="profile-socialmedia-icon" src={instagram_logo}></img> @{instagram}</a>}
                            {tiktok !== "" && <a className='profile-socialmedia' href={`https://tiktok.com/@${tiktok}`} target="_blank"><img className="profile-socialmedia-icon" src={tiktok_logo}></img> @{tiktok}</a>}
                            {x !== "" && <a className='profile-socialmedia' href={`https://x.com/${x}`} target="_blank"><img className="profile-socialmedia-icon" src={x_logo}></img> @{x}</a>}
                        </div>
                        <div className="profile-interact">
                            <Button className='profile-button' href={`/request/${username}`}>Chat with {firstName}</Button>
                            <div className="profile-rating">
                                <div className="profile-rating-top">
                                    {renderStars(ratingScore)}
                                    <span className="rating-score">{ratingScore.toFixed(1)}</span>
                                </div>
                                <div className="rating-text">Response Rating</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-section">
                <div className="profile-header">About {firstName}</div>
                <div className="profile-bio">{bio}</div>
            </div>
            <div className="profile-section">
                <div className="profile-header">Areas of insight</div>
                {interests.map((interest, index) => (
                    <div key={index} className='profile-expertise'><RxLightningBolt className="profile-expertise-icon" />{interest}</div>
                ))}
            </div>
        </div>
    );
}

export default AthleteProfilePage;