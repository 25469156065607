import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import debounce from 'lodash.debounce';


import runner_holding_phone from '../runner_holding_phone2.jpeg';
import phone_hero from '../phone_hero.png';
import phone_hero2 from '../phone_hero2.png';
import phone_hero3 from '../phone_hero3.png';
import phone_hero4 from '../phone_hero4.png';
import pitch_image from '../homepage_tennis.jpg';
// Icons
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { GiBiceps } from "react-icons/gi";
import { FaGears } from "react-icons/fa6";
import { BsHeartPulseFill } from "react-icons/bs";
import { FaPersonRunning } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import expertise_icon2 from '../expertise_icon2.svg';
import fans_icon2 from '../fans_icon2.svg';
import passion_icon2 from '../passion_icon2.svg';
import pace_icon2 from '../pace_icon2.svg';
import expertise_icon from '../expertise_icon.svg';
import fans_icon from '../fans_icon.svg';
import passion_icon from '../passion_icon.svg';
import pace_icon from '../pace_icon.svg';
import world_icon from '../world_icon.svg';
import exclusive_icon from '../exclusive_icon.svg';
import filter_icon from '../filter_icon.svg';
import direct_icon from '../direct_icon.svg';
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { BsChatTextFill } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { BsPersonVideo2 } from "react-icons/bs";
import { GiCardExchange } from "react-icons/gi";

import { RiMoneyDollarCircleFill } from "react-icons/ri";

import quote_icon from '../quote-icon.svg';
import quote_crew from '../quote-crew.jpg';
import quote_lifting from '../quote-lifting.jpg';
import quote_baseball from '../quote-baseball.jpg';
import quote_distance from '../quote-distance.jpg';
import quote_crossfit from '../quote-crossfit.jpg';
import quote_burger from '../quote-burger.jpg';
import quote_friends from '../quote-friends.jpg';

import lightning_white_icon from '../lightning_white_icon.svg';
import lightning_yellow_icon from '../lightning_yellow_icon.svg';


function HomePage() {
    // Javascript animations for page sections and elements --------------------------------------------->
    // Jump to sections smoothly animation
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    // Collapse/Expand "Why" Sections
    const [isCollapsed, setIsCollapsed] = useState({
        section1: window.innerWidth < 830,
        section2: window.innerWidth < 830,
    });
    const toggleSection = (section) => {
        setIsCollapsed((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
    useEffect(() => {
        // Only update if the window width changes significantly. Shit will break if you delete this
        let windowWidth = window.innerWidth;
        const handleResize = () => {
            if (Math.abs(window.innerWidth - windowWidth) > 50) {
                windowWidth = window.innerWidth;
    
                if (window.innerWidth < 830) {
                    setIsCollapsed({
                        section1: true,
                        section2: true,
                    });
                } else {
                    setIsCollapsed({
                        section1: false,
                        section2: false,
                    });
                }
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Animation for "Pitch" Section
    useEffect(() => {
        const pitchWrapper = document.querySelector('.home-pitch-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (pitchWrapper) {
            observer.observe(pitchWrapper);
        }
        return () => {
            if (pitchWrapper) {
                observer.unobserve(pitchWrapper);
            }
        };
    }, []);
    // Animations for "Pitch" Statistics
    useEffect(() => {
        const icons = document.querySelectorAll('.home-pitch-statistic');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`;
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        icons.forEach(icon => {
            observer.observe(icon);
        });
        return () => {
            icons.forEach(icon => {
                observer.unobserve(icon);
            });
        };
    }, []);
    // Animation "How" Section
    useEffect(() => {
        const howWrapper = document.querySelector('.home-how-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (howWrapper) {
            observer.observe(howWrapper);
        }
        return () => {
            if (howWrapper) {
                observer.unobserve(howWrapper);
            }
        };
    }, []);
    // Animation for "How it works" section
    useEffect(() => {
        const elements = document.querySelectorAll('.home-how-timeline-content');
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('fade-in');
              observer.unobserve(entry.target);
            }
          });
        }, {
          threshold: 0.1
        });
        elements.forEach(element => {
          observer.observe(element);
        });
        return () => {
          elements.forEach(element => {
            observer.unobserve(element);
          });
        };
    }, []);
    // Animation for "Why" Section
    useEffect(() => {
        const whyWrapper = document.querySelector('.home-why-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (whyWrapper) {
            observer.observe(whyWrapper);
        }
        return () => {
            if (whyWrapper) {
                observer.unobserve(whyWrapper);
            }
        };
    }, []);
    // Animations for "Why" Entries (Icons and text)
    useEffect(() => {
        const icons = document.querySelectorAll('.home-why-col');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`;
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        icons.forEach(icon => {
            observer.observe(icon);
        });
        return () => {
            icons.forEach(icon => {
                observer.unobserve(icon);
            });
        };
    }, []);
    // Animation for "Call to action" section
    useEffect(() => {
        const ctaWrapper = document.querySelector('.home-cta-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target); // Stop observing once the animation is applied
                }
            });
        }, {
            threshold: 0.1 // Adjust this value as needed
        });
        if (ctaWrapper) {
            observer.observe(ctaWrapper);
        }
        return () => {
            if (ctaWrapper) {
                observer.unobserve(ctaWrapper);
            }
        };
    }, []);
    // Actual page content --------------------------------------------------------------------------------------------------------------------->
    return(
        <div className='home-wrapper'>
            {/* Hero Section */}
            <div className='home-hero-wrapper-container'>
            <div className='home-hero-wrapper' sm={1} lg={2}>
                <div className='home-hero-wrapper-overlay'></div>
                <div className='home-hero-wrapper-color-overlay'></div>
                <div className='home-hero-row'>
                    <div className='home-hero-col-text'>
                        <div className='home-hero-container'>
                            <div className='home-hero-huge deb'>Where Fans Learn, and Leaders Earn.</div>
                            <div className='home-hero-text'>
                                Infield lets fan engage 1:1 with sports leaders face-to-face. Fans get world-class insights are their fingertips, and 
                                leaders make the compensation they deserve.
                            </div>
                            <div className='home-hero-button-container'>
                                <Button className='home-hero-button-join' href="/roster">Ask an Infielder</Button>
                                <Button className='home-hero-button-list' href="/join">Become an Infielder</Button>
                            </div>
                        </div>
                    </div>
                    <div className='home-hero-col-image'>
                        <img className="home-hero-picture" src={phone_hero2} />
                    </div>
                </div>
            </div>
        </div>

        {/* Pitch Section */}
        <div className='home-pitch-wrapper'>
            <div className='home-pitch-row' sm={1} lg={2}>
                <div className='home-pitch-col-visuals'>
                    {/* <div className='home-pitch-title-centered'>
                        With 1-on-1 feedback from pro athletes, you can see
                    </div>
                    <div className='home-pitch-statistics-container'>
                        <div className='home-pitch-statistic'>
                            <GiBiceps className='home-pitch-icon' />
                            <div className='home-pitch-title'>
                                <div>20% higher strength gains over 12 weeks</div>
                            </div>
                            <div className='home-pitch-source'>
                                Human Kinematics (2007)
                            </div>
                        </div>
                        <div className='home-pitch-statistic'>
                            <FaGears className='home-pitch-icon' />
                            <div className='home-pitch-title'>
                                <div>25% higher skill proficiency over 4 weeks</div>
                            </div>
                            <div className='home-pitch-source'>
                                Journal of Sports Sciences (2019)
                            </div>
                        </div>
                        <div className='home-pitch-statistic'>
                            <BsHeartPulseFill className='home-pitch-icon' />
                            <div className='home-pitch-title'>
                                <div>35% lower re-injury rates for past injuries</div>
                            </div>
                            <div className='home-pitch-source'>
                                Clinical Journal of Sport Medicine (2010)
                            </div>
                        </div>
                    </div> */}
                    <img className="home-pitch-picture" src={pitch_image} />
                </div>
                <div className='home-pitch-col-text'>
                    <div className='home-pitch-huge'>
                        Unlock Your Athletic Potential
                    </div>
                    <div className='home-pitch-text'>
                        The insights of elite athletes and sports experts can help you crush your goals. Ask the people who've already been there, and done that.
                    </div>
                    <div className='home-pitch-text'>
                        <b>
                            Infield is designed to get you personalized, behind-the scenes answers—delivered right 
                            to you, no matter where you are.
                        </b>
                    </div>
                    <div className='home-pitch-button-container'>
                        <button className='home-pitch-button' onClick={() => {scrollToSection('why_fans'); toggleSection('section2');}}>
                            <IoPersonSharp className='home-pitch-button-icon' />
                            Benefits for Fans
                        </button>
                        <button className='home-pitch-button' onClick={() => {scrollToSection('why_athletes'); toggleSection('section1');}}>
                            <FaPersonRunning className='home-pitch-button-icon' />
                            Benefits for Infielders
                        </button>
                    </div>
                </div>
            </div>
        </div>

            {/* How */}
            <div className='home-how-wrapper'>
                <div className='home-how-header'>
                    <b>How it works</b>
                </div>
                <div className='home-how-row'>
                    <div className='home-how-col-slideshow'>
                        <div className='home-how-slideshow'>
                            <Carousel indicators={false}>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_distance} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                What's a healthy way to fuel my long runs without overspending on gels?
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_lifting} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                Do you think squat and deadlift are important to become an elite marathon runner? If so, how many times per week?
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_friends} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                What did you wish you knew about college life before you started, and how did you meet your closest friends there?
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_crew} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                What supplements do you take? I've been wanting to try creatine, but I'm worried about side effects.
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_baseball} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                Should my son specialize to become a pro baseball player? He's 13 and I want to know the trade-offs.
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_crossfit} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                As a pro Crossfit athlete, Do you ever have to train through injuries? When do you know to give your body a break?
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='home-how-slideshow-slide'>
                                        <img className="home-how-slideshow-image" src={quote_burger} />
                                        <div className='home-how-slideshow-image-overlay'></div>
                                        <div className='home-how-slideshow-container'>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                            <div className='home-how-slideshow-text'>
                                                What's your favorite cheat meal after a game?
                                            </div>
                                            <img className='home-how-slideshow-quote-icon' src={quote_icon}></img>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="home-how-timeline">
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>1</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><FaPaperPlane className='home-how-timeline-icon'/>Direct DM routing</div>
                                <div className='home-how-timeline-text'>
                                    Infield manages DM requests, so your requests will always make it into our system.
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>2</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><MdVerified className='home-how-timeline-icon'/>You ask, we verify</div>
                                <div className='home-how-timeline-text'>
                                    When you make a request, we verify it for quality and relevance after help from AI.
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>3</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><BsPersonVideo2 className='home-how-timeline-icon'/>Infielders choose their responses</div>
                                <div className='home-how-timeline-text'>
                                    Infielders have 72 hours to send a video response. If they don't respond, you get an immediate refund.
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>4</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><GiCardExchange className='home-how-timeline-icon'/>Get your personalized answer</div>
                                <div className='home-how-timeline-text'>We only process payment when the Infielder's response is in your inbox.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why */}
            <div className="home-why-wrapper">
                <div className='home-why-header' id="why_athletes" onClick={() => toggleSection('section1')}>
                    <b>Athletes and experts become <span className='text-infield-gold'>Infielders</span></b>
                    <div>
                        {isCollapsed.section1 ? <IoIosArrowForward className='home-why-header-icon'/> : <IoIosArrowDown className='home-why-header-icon'/>}
                    </div>
                </div>
                <div className={`home-why-row ${isCollapsed.section1 ? 'collapsed' : ''}`} sm={1} xxl={4}>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={expertise_icon}></img>
                        <div className='home-why-title'><b>Monetize your expertise</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Choose your rate, engage your followers, and see just how much your expertise is worth.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={passion_icon}></img>
                        <div className='home-why-title'><b>Focus on your goals</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Never worry about sifting through inappropriate DMs or chasing down payments. Just focus on doing you.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={fans_icon}></img>
                        <div className='home-why-title'><b>Grow your true fans</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Build 1:1 connection with your true fans all over the world — let us block out the haters and bots. 
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={pace_icon}></img>
                        <div className='home-why-title'><b>Answer only what you want</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Choose what you want to answer, and when. You can engage hundrends of fans, or just mentor a few at a time. 
                        </div>
                    </div>
                </div>
                <hr className='home-why-divider' />
                <div className='home-why-header' id="why_fans" onClick={() => toggleSection('section2')}>
                    <b>And Their <span className='text-infield-blue'>Fans</span> Love It</b>
                    <div>
                        {isCollapsed.section2 ? <IoIosArrowForward className='home-why-header-icon'/> : <IoIosArrowDown className='home-why-header-icon'/>}
                    </div>
                </div>
                <div className={`home-why-row ${isCollapsed.section2 ? 'collapsed' : ''}`} sm={1} xxl={4}>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={exclusive_icon}></img>
                        <div className='home-why-title'><b>Your questions, answered</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Social Media DMs to elite athletes get lost in the crowd. <b>Infield requests never do.</b>
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={direct_icon}></img>
                        <div className='home-why-title'><b>Face-to-face engagement</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Gain direct access to world-class athletes and experts, no signup required.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={filter_icon}></img>
                        <div className='home-why-title'><b>Trusted personal insight</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Ignore random tips and Google searches, and talk to those who've lived it!
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={world_icon}></img>
                        <div className='home-why-title'><b>Unparalleled global reach</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Connect with the best all over the world.
                        </div>
                    </div>
                </div>
            </div>

            {/* Call */}
            <div className='home-cta-wrapper'>
                <div className='home-cta-row'>
                    <div className='home-cta-col'>
                        <div className='home-cta-icon-container'>
                            <RiMoneyDollarCircleFill className='home-cta-icon1' />
                            <div className='home-cta-icon-background'></div>
                            <RiMoneyDollarCircleFill className='home-cta-icon2' />
                        </div>
                        <div className='home-cta-col-text'>
                            <div className='home-cta-header'>Start earning with Infield</div>
                            <div className='home-cta-text'>
                                Join a community of world-class sports leaders and their fans, and reap the benefits of the expertise you've gained.
                            </div>
                            <Button className='home-cta-button' href="/join">Become an athlete</Button>
                        </div>
                    </div>
                    <div className='home-cta-col'>
                        <div className='home-cta-icon-container'>
                            <BsChatTextFill className='home-cta-icon1' />
                            <div className='home-cta-icon-background'></div>
                            <BsChatTextFill className='home-cta-icon2' />
                        </div>
                        <div className='home-cta-col-text'>
                            <div className='home-cta-header'>Start learning with Infield</div>
                            <div className='home-cta-text'>
                                Gain face-to-face insight from the leaders you trust, foster a connection, and take your game to the next level.
                            </div>
                            <Button className='home-cta-button' href="/roster">Ask an athlete</Button>
                        </div>
                    </div>
                </div>
                <div className='home-cta-row'>
                    <div className='home-cta-dialogue'>
                        Infield was developed by Olympians, high-performers, and their fans.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
