import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../SupabaseClient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import { format } from 'date-fns';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import ConfirmationModal from '../components/ConfirmationModal'; // Import the ConfirmationModal component
import ConfirmationAlert from '../components/ConfirmationAlert'; // Import ConfirmationAlert

const AdminPage = () => {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [athleteFilter, setAthleteFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('new');
  const [athletes, setAthletes] = useState([]);
  const [selectedAthlete, setSelectedAthlete] = useState('');
  const [responseVideo, setResponseVideo] = useState(null);
  // sgMail.setApiKey("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");

  // Authentication and login
  const [session, setSession] = useState(null);
  useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
          setSession(session);
      });
      const {
          data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
          setSession(session);
      });

      return () => subscription.unsubscribe();
  }, []);
  // Page redirection if the user isn't an admin----------------------------------------------------------------->
  useEffect(() => {
    const pageRedirect = async () => {
      const { data: user, error: authError } = await supabase.auth.getUser();
      if (authError || !user) {
          console.error('Error fetching user:', authError);
          return;
      }
      const userRole = user.user.user_metadata.role;
      if (userRole === 'athlete') {
        navigate('/dashboard');
      } else if (userRole != 'admin') {
        navigate('/');
      }
    };
    if (session) {
      pageRedirect();
    }
    }, [session]);

    
  useEffect(() => {
    // Get athletes for filter
    const fetchAthletes = async () => {
        const { data, error } = await supabase
          .from('athletes')
          .select('username'); // Assuming 'username' is the column that holds the athlete's name
        if (error) {
          console.error('Error fetching athletes:', error);
        } else {
          setAthletes(data);
        }
      };
    // Get submissions
    const fetchSubmissions = async () => {
      const { data, error } = await supabase
        .from('submissions')
        .select('*');
      if (error) {
        console.error('Error fetching submissions:', error);
      } else {
        setSubmissions(data);
        setFilteredSubmissions(data); // Initially, show all submissions
      }
    };
    fetchAthletes();
    fetchSubmissions();
  }, []);

  // Filter by athlete and submission type
  useEffect(() => {
    const applyFilters = () => {
      let filtered = submissions;
      if (athleteFilter) {
        filtered = filtered.filter(submission => submission.athlete === athleteFilter);
      }

      if (statusFilter) {
        filtered = filtered.filter(submission => submission.status === statusFilter);
      }
      setFilteredSubmissions(filtered);
    };
    applyFilters();
  }, [athleteFilter, statusFilter, submissions]);

  // Selected Submission ----------------------------------------------------------------->
  const handleSelectSubmission = async (submission) => {
    setSelectedSubmission(submission);
      const { data, error } = await supabase
        .from('athletes')
        .select('*')
        .eq('username', submission.athlete)
        .single();
      if (error) {
        console.error('Error fetching athlete profile:', error);
      } else {
        setSelectedAthlete(data);
      }

  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onConfirm: null,
    showTextarea: false,
    placeholderText: '',
  });
  const [rejectionNote, setRejectionNote] = useState('');
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false); // State for alert visibility
  const [alertMessage, setAlertMessage] = useState('');

// Function to send rejection email
const sendRejectEmail = async () => {
  const emailData = {
    reply_to: 'info@infieldconnect.co',
    requester_email: selectedSubmission.email,
    requester_name: selectedSubmission.firstName,
    athlete_username: selectedSubmission.athlete,
    rejection_note: rejectionNote,
  };

  try {
    const response = await axios.post('https://infield-backend.vercel.app/reject-request', emailData);
    console.log(response.data);
    setAlertMessage('Request rejected, and the requester has been notified'); // Set alert message
    setShowConfirmationAlert(true); // Show the confirmation alert
  } catch (error) {
    console.error('Failed to send rejection email:', error);
    alert('Failed to send rejection email.');
  }
};


    // Update status of submission
    const handleUpdateStatus = async (newStatus) => {
      if (newStatus === 'rejected') {
        setModalConfig({
          title: 'Reject Submission and Notify Submitter',
          message: 'Give the submitter a reason why the request was rejected',
          confirmText: 'Reject and Send',
          cancelText: 'Cancel',
          placeholderText: 'e.g. this is a no-go topic',
          onConfirm: async () => {
            if (selectedSubmission) {
              await sendRejectEmail(rejectionNote);
              await updateSubmissionStatus(newStatus);
            }
            setIsModalOpen(false);
          },
          showTextarea: true,
        });
        setRejectionNote(rejectionNote);
        setIsModalOpen(true);
        return; // Exit the function if the modal is opened
      }
  
      await updateSubmissionStatus(newStatus);
    };
  
  const updateSubmissionStatus = async (newStatus) => {
    if (selectedSubmission) {
      const { data, error } = await supabase
        .from('submissions')
        .update({ status: newStatus })
        .eq('id', selectedSubmission.id);
      if (error) {
        console.error('Error updating status:', error);
      } else {
        // Delete submission if rejected
        if (newStatus === 'rejected') {
          if (selectedSubmission.messageType === 'video') {
            await deleteVideoFromStorage(selectedSubmission.video);
          }
          const { error: deleteError } = await supabase
            .from('submissions')
            .delete()
            .eq('id', selectedSubmission.id);
          if (deleteError) {
            console.error('Error deleting submission:', deleteError);
          }
        }
        // Send notification email to athlete if approved
        if (newStatus == "approved") {
          const { data: athleteData, error: athleteError } = await supabase
            .from('athletes')
            .select('*')
            .eq('username', selectedSubmission.athlete)
            .single();
          if (athleteError) {
            console.error('Error fetching athlete email:', athleteError);
          } else {
            sendEmail(athleteData); // Comment this out if you don't want to send an email
          }
        }
        // Clear selection and refresh dashboard
        setSelectedSubmission(null); 
        const fetchSubmissions = async () => {
          const { data, error } = await supabase
            .from('submissions')
            .select('*');
          if (error) {
            console.error('Error fetching submissions:', error);
          } else {
            setSubmissions(data);
            setFilteredSubmissions(data);
          }
        };
        fetchSubmissions();
      }
    }
  };
  // Function to delete video from storage if rejected
  const deleteVideoFromStorage = async (videoUrl) => {
    const videoPath = videoUrl.split('/').pop(); // Extract the video path from the URL
    const { error } = await supabase.storage
      .from('videos')
      .remove([`submissions/${videoPath}`]);
    if (error) {
      console.error('Error deleting video from storage:', error);
    }
  };

  // Send email to athlete to notify them
  const sendEmail = async (athleteData) => {
    const emailData = {
      reply_to: 'info@infieldconnect.co',
      athlete_email: athleteData.email,
      athlete_firstName: athleteData.firstName,
      requester_name: `${selectedSubmission.firstName} ${selectedSubmission.lastName}`,
    };
  
    try {
      const response = await axios.post('https://infield-backend.vercel.app/new-request', emailData);
      // const response = await axios.post('http://localhost:3001/new-request', emailData);
      console.log(response.data);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };
  

  const [feedbackEntries, setFeedbackEntries] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [adminNotes, setAdminNotes] = useState('');

  useEffect(() => {
    const fetchFeedbackEntries = async () => {
      const { data, error } = await supabase
        .from('user_feedback')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching feedback entries:', error);
      } else {
        setFeedbackEntries(data);
      }
    };
    fetchFeedbackEntries();
  }, []);

  const handleSelectFeedback = (feedback) => {
    setSelectedFeedback(feedback);
    setAdminNotes(feedback.admin_notes || '');
  };

  // Rename this function to avoid conflict
  const handleUpdateFeedbackStatus = async (newStatus) => {
    if (selectedFeedback) {
      const { error } = await supabase
        .from('user_feedback')
        .update({ status: newStatus })
        .eq('id', selectedFeedback.id);
      if (error) {
        console.error('Error updating status:', error);
      } else {
        setSelectedFeedback({ ...selectedFeedback, status: newStatus });
        setFeedbackEntries(feedbackEntries.map(entry =>
          entry.id === selectedFeedback.id ? { ...entry, status: newStatus } : entry
        ));
      }
    }
  };

  const handleUpdateNotes = async () => {
    if (selectedFeedback) {
      const { error } = await supabase
        .from('user_feedback')
        .update({ admin_notes: adminNotes })
        .eq('id', selectedFeedback.id);
      if (error) {
        console.error('Error updating admin notes:', error);
      } else {
        setSelectedFeedback({ ...selectedFeedback, admin_notes: adminNotes });
      }
    }
  };

  const [contactRequests, setContactRequests] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactNotes, setContactNotes] = useState('');

  useEffect(() => {
    const fetchContactRequests = async () => {
      const { data, error } = await supabase
        .from('contact_form')  // Changed from 'contact_requests' to 'contact_form'
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching contact requests:', error);
      } else {
        setContactRequests(data);
      }
    };
    fetchContactRequests();
  }, []);

  const handleSelectContact = (contact) => {
    setSelectedContact(contact);
    setContactNotes(contact.notes || '');
  };

  const handleUpdateContactStatus = async (newStatus) => {
    if (selectedContact) {
      const { error } = await supabase
        .from('contact_form')  // Changed from 'contact_requests' to 'contact_form'
        .update({ status: newStatus })
        .eq('id', selectedContact.id);
      if (error) {
        console.error('Error updating contact status:', error);
      } else {
        setSelectedContact({ ...selectedContact, status: newStatus });
        setContactRequests(contactRequests.map(contact =>
          contact.id === selectedContact.id ? { ...contact, status: newStatus } : contact
        ));
      }
    }
  };

  const handleUpdateContactNotes = async () => {
    if (selectedContact) {
      const { error } = await supabase
        .from('contact_form')  // Changed from 'contact_requests' to 'contact_form'
        .update({ notes: contactNotes })
        .eq('id', selectedContact.id);
      if (error) {
        console.error('Error updating contact notes:', error);
      } else {
        setSelectedContact({ ...selectedContact, notes: contactNotes });
      }
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const [athleteRequests, setAthleteRequests] = useState([]);
  const [selectedAthleteRequest, setSelectedAthleteRequest] = useState(null);
  const [athleteRequestNotes, setAthleteRequestNotes] = useState('');

  useEffect(() => {
    const fetchAthleteRequests = async () => {
      const { data, error } = await supabase
        .from('athlete_form')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching athlete requests:', error);
      } else {
        setAthleteRequests(data);
      }
    };
    fetchAthleteRequests();
  }, []);

  const handleSelectAthleteRequest = (athlete) => {
    setSelectedAthleteRequest(athlete);
    setAthleteRequestNotes(athlete.notes || '');
  };

  const handleUpdateAthleteRequestStatus = async (newStatus) => {
    if (selectedAthleteRequest) {
      const { error } = await supabase
        .from('athlete_form')
        .update({ status: newStatus })
        .eq('id', selectedAthleteRequest.id);
      if (error) {
        console.error('Error updating athlete request status:', error);
      } else {
        setSelectedAthleteRequest({ ...selectedAthleteRequest, status: newStatus });
        setAthleteRequests(athleteRequests.map(athlete =>
          athlete.id === selectedAthleteRequest.id ? { ...athlete, status: newStatus } : athlete
        ));
      }
    }
  };

  const handleUpdateAthleteRequestNotes = async () => {
    if (selectedAthleteRequest) {
      const { error } = await supabase
        .from('athlete_form')
        .update({ notes: athleteRequestNotes })
        .eq('id', selectedAthleteRequest.id);
      if (error) {
        console.error('Error updating athlete request notes:', error);
      } else {
        setSelectedAthleteRequest({ ...selectedAthleteRequest, notes: athleteRequestNotes });
      }
    }
  };

  // New useEffect hook to fetch the response video for the selected submission
  useEffect(() => {
    const fetchResponseVideo = async () => {
      if (selectedSubmission && selectedSubmission.status === 'completed') {
        const { data, error } = await supabase
          .from('responses')
          .select('video_link')
          .eq('submission_ID', selectedSubmission.id)
          .limit(1)
          .single();

        if (error) {
          console.error('Error fetching response video:', error);
          setResponseVideo(null);
        } else if (data) {
          setResponseVideo(data.video_link);
        } else {
          setResponseVideo(null);
        }
      } else {
        setResponseVideo(null);
      }
    };

    fetchResponseVideo();
  }, [selectedSubmission]);

  const [userFeedbackEntries, setUserFeedbackEntries] = useState([]);
  const [selectedUserFeedback, setSelectedUserFeedback] = useState(null);

  // Fetch user feedback entries
  useEffect(() => {
    const fetchUserFeedbackEntries = async () => {
      const { data, error } = await supabase
        .from('requester_feedback')
        .select('*')
        .order('created_at', { ascending: false });
      if (error) {
        console.error('Error fetching user feedback entries:', error);
      } else {
        setUserFeedbackEntries(data);
      }
    };
    fetchUserFeedbackEntries();
  }, []);

  const handleSelectUserFeedback = (feedback) => {
    setSelectedUserFeedback(feedback);
  };

  if (!session) {
    return (
        <div className="admin-page-format">
            <div className="contact-form">
                <div className="contact-title">Sign in</div>
                <Auth
                    supabaseClient={supabase}
                    appearance={{ theme: ThemeSupa }}
                    providers={[]}
                    showLinks={false}
                    localization={{
                        variables: {
                            sign_in: {
                                email_label: 'Your email address',
                                password_label: 'Your password',
                            },
                            forgotten_password: {
                                button_label: 'Forgot password?',
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
} else {
  return (
    <div className='admin-page-format'>
      {showConfirmationAlert && (
        <ConfirmationAlert
          message={alertMessage}
          onClose={() => setShowConfirmationAlert(false)} // Close the alert
        />
      )}
        <div className="admin-dashboard">
        <Tabs defaultActiveKey="dashboard" id="admin-tabs" className='dashboard-tab-title'>
          <Tab eventKey="dashboard" title="Dashboard">
            <div className='admin-dashboard-text'>
                <label style={{ marginRight: '20px', }}>
                  Filter by Athlete:
                <select
                  className="admin-dashboard-filter"
                    value={athleteFilter}
                    onChange={e => setAthleteFilter(e.target.value)}
                >
                  <option value="">All Athletes</option>
                  {athletes.map((athlete, index) => (
                  <option key={index} value={athlete.username}>
                      {athlete.username}
                  </option>
                ))}
                </select>
                </label>
                <label style={{ marginTop: '10px',}}>
                  Filter by Status:
                <select
                  className="admin-dashboard-filter"
                  value={statusFilter}
                  onChange={e => setStatusFilter(e.target.value)}
                >
                  <option value="">All submission</option>
                  <option value="new">New</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="athlete rejected">Athlete Rejected</option>
                  <option value="completed">Completed</option>
                </select>
                </label>
            </div>
            <div className='admin-dashboard-table-container'>
              <table className='admin-dashboard-table'>
                <thead>
                  <tr>
                      <th className='admin-dashboard-table-header'>ID</th>
                      <th className='admin-dashboard-table-header'>Athlete</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                  </tr>
                </thead>
                <tbody>
                {filteredSubmissions
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort on FIFO
                  .map((submission, index) => (
                    <tr
                      key={index}
                      style={{ cursor: 'pointer', backgroundColor: selectedSubmission?.id === submission.id ? '#f0f0f0' : 'white' }}
                      onClick={() => handleSelectSubmission(submission)}
                    >
                      <td>{submission.id}</td>
                      <td>{submission.athlete}</td>
                      <td>
                        <div className={`admin-dashboard-table-status admin-dashboard-table-status-${submission.status.toLowerCase().replace(' ', '-')}`}>
                          {submission.status}
                        </div>
                      </td>
                      <td>
                          {new Date(submission.createdAt + 'Z').toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })} 
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
            {selectedSubmission && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-admin'>
                    <div className='admin-dashboard-header'>Requester info</div>
                    <div className='admin-dashboard-request-info'><b>Name:</b> {selectedSubmission.firstName} {selectedSubmission.lastName}</div>
                    <div className='admin-dashboard-request-info'><b>Email:</b> {selectedSubmission.email}</div>
                    <div className='admin-dashboard-request-info'><b>Subject:</b> {selectedSubmission.subject}</div>
                    <div className='admin-dashboard-header'>Requester content</div>
                    {selectedSubmission.messageType === 'text' && <td>{selectedSubmission.message}</td>}
                    {selectedSubmission.messageType === 'video' && (
                      <SecureVideoPlayer
                        src={selectedSubmission.video}
                        className='admin-dashboard-video'
                      />
                    )}
                    {selectedSubmission.status === 'completed' && responseVideo && (
                      <>
                        <div className='admin-dashboard-header'>Response video</div>
                        <SecureVideoPlayer
                          src={responseVideo}
                          className='admin-dashboard-video'
                        />
                      </>
                    )}
                    <div className='admin-button-container'>
                      {selectedSubmission.status === "new" &&
                        <button className="admin-button-approve" onClick={() => handleUpdateStatus('approved')}>Approve</button>
                      }
                      {selectedSubmission.status === "new" &&
                        <button className="admin-button-reject" onClick={() => handleUpdateStatus('rejected')}>Reject</button>
                      }
                      {selectedSubmission.status === "athlete rejected" &&
                        <div>
                          <div className='admin-dashboard-header'>Athlete's rejection note:</div>
                          <div className='admin-dashboard-request-info'>{selectedSubmission.rejection_note}</div>
                        </div>
                      }
                      <select 
                        onChange={(e) => handleUpdateStatus(e.target.value)} 
                        className="admin-status-button"
                        defaultValue="Change Status"
                      >
                        <option disabled>Change Status</option>
                        <option value="new">New</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="athlete rejected">Athlete Rejected</option>
                        <option value="completed">Completed</option>
                      </select>
                    </div>
                </div>
                <div className='admin-dashboard-request-col-details'>
                  <div className='admin-dashboard-header'>Athlete's No-go topics</div>
                    <div>
                      <div className='admin-dashboard-request-info'>{selectedAthlete.no_go_topics}</div>
                    </div>
                </div>
              </div>
            )}
            </Tab>
            <Tab eventKey="AthleteFeedback" title="Athlete Feedback">
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Username</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedbackEntries.map((entry) => (
                      <tr
                        key={entry.id}
                        onClick={() => handleSelectFeedback(entry)}
                        style={{ cursor: 'pointer', backgroundColor: selectedFeedback?.id === entry.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{entry.username}</td>
                        <td>{format(new Date(entry.created_at), 'MMMM d, yyyy')}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(entry.status || '').toLowerCase().replace(' ', '-')}`}>
                            {entry.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedFeedback && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Feedback Details</h3>
                    <p><strong>Username:</strong> {selectedFeedback.username}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedFeedback.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedFeedback.status}</p>
                    <p><strong>Content:</strong> {selectedFeedback.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateFeedbackStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedFeedback.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="Awaiting Response">Awaiting Response</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="admin-notes">Admin notes:</label>
                      <textarea
                        id="admin-notes"
                        value={adminNotes}
                        onChange={(e) => setAdminNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateNotes} className='admin-update-notes-button'>Update Notes</button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="UserFeedback" title="User Feedback">
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>ID</th>
                      <th className='admin-dashboard-table-header'>Athlete Username</th>
                      <th className='admin-dashboard-table-header'>Rating</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userFeedbackEntries.map((entry) => (
                      <tr
                        key={entry.id}
                        onClick={() => handleSelectUserFeedback(entry)}
                        style={{ cursor: 'pointer', backgroundColor: selectedUserFeedback?.id === entry.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{entry.id}</td>
                        <td>{entry.athlete_username}</td>
                        <td>{entry.rating}</td>
                        <td>{format(new Date(entry.created_at), 'MMMM dd, yyyy')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedUserFeedback && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <p><strong>ID:</strong> {selectedUserFeedback.id}</p>
                    <p><strong>Athlete Username:</strong> {selectedUserFeedback.athlete_username}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedUserFeedback.created_at), 'MMMM dd, yyyy')}</p>
                    <p><strong>Request ID:</strong> {selectedUserFeedback.request_id}</p>
                    <p><strong>Response ID:</strong> {selectedUserFeedback.response_id}</p>
                    <p><strong>Rating:</strong> {selectedUserFeedback.rating}</p>
                    <p><strong>Response Feedback:</strong> {selectedUserFeedback.response_feedback}</p>
                    <p><strong>Infield Feedback:</strong> {selectedUserFeedback.infield_feedback}</p>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="Contact" title="Contact Requests">
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Name</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Subject</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactRequests.map((contact) => (
                      <tr
                        key={contact.id}
                        onClick={() => handleSelectContact(contact)}
                        style={{ cursor: 'pointer', backgroundColor: selectedContact?.id === contact.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{`${contact.first_name} ${contact.last_name}`}</td>
                        <td>{format(new Date(contact.created_at), 'MMMM d, yyyy')}</td>
                        <td>{truncateText(contact.subject, 20)}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(contact.status || '').toLowerCase().replace(' ', '-')}`}>
                            {contact.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedContact && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Contact Request Details</h3>
                    <p><strong>Name:</strong> {`${selectedContact.first_name} ${selectedContact.last_name}`}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedContact.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedContact.status || 'N/A'}</p>
                    <p><strong>Email:</strong> {selectedContact.email}</p>
                    <p><strong>Subject:</strong> {selectedContact.subject}</p>
                    <p><strong>Content:</strong> {selectedContact.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateContactStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedContact.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="In-Progress">In-Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="contact-notes">Admin notes:</label>
                      <textarea
                        id="contact-notes"
                        value={contactNotes}
                        onChange={(e) => setContactNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateContactNotes} className='admin-update-notes-button'>Update Notes</button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="AthleteRequest" title="Athlete Requests">
              <div className='admin-dashboard-table-container'>
                <table className='admin-dashboard-table'>
                  <thead>
                    <tr>
                      <th className='admin-dashboard-table-header'>Name</th>
                      <th className='admin-dashboard-table-header'>Date</th>
                      <th className='admin-dashboard-table-header'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {athleteRequests.map((athlete) => (
                      <tr
                        key={athlete.id}
                        onClick={() => handleSelectAthleteRequest(athlete)}
                        style={{ cursor: 'pointer', backgroundColor: selectedAthleteRequest?.id === athlete.id ? '#f0f0f0' : 'white' }}
                      >
                        <td>{`${athlete.first_name} ${athlete.last_name}`}</td>
                        <td>{format(new Date(athlete.created_at), 'MMMM d, yyyy')}</td>
                        <td>
                          <div className={`admin-dashboard-table-status admin-dashboard-table-status-${(athlete.status || '').toLowerCase().replace(' ', '-')}`}>
                            {athlete.status || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedAthleteRequest && (
                <div className='admin-dashboard-request-container'>
                  <div className='admin-dashboard-request-col-details'>
                    <h3>Athlete Request Details</h3>
                    <p><strong>Name:</strong> {`${selectedAthleteRequest.first_name} ${selectedAthleteRequest.last_name}`}</p>
                    <p><strong>Date:</strong> {format(new Date(selectedAthleteRequest.created_at), 'MMMM d, yyyy')}</p>
                    <p><strong>Status:</strong> {selectedAthleteRequest.status || 'N/A'}</p>
                    <p><strong>Email:</strong> {selectedAthleteRequest.email}</p>
                    <p><strong>Location:</strong> {`${selectedAthleteRequest.city}, ${selectedAthleteRequest.country}`}</p>
                    <p><strong>Social:</strong> {`${selectedAthleteRequest.social_platform}: ${selectedAthleteRequest.social_account}`}</p>
                    <p><strong>Sport:</strong> {`${selectedAthleteRequest.sport}, ${selectedAthleteRequest.position}`}</p>
                    <p><strong>Content:</strong> {selectedAthleteRequest.content}</p>
                  </div>
                  <div className='admin-dashboard-request-col-admin'>
                    <h3>Admin Actions</h3>
                    <select 
                      onChange={(e) => handleUpdateAthleteRequestStatus(e.target.value)}
                      className="admin-status-button"
                      value={selectedAthleteRequest.status || ''}
                    >
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="In-Progress">In-Progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div className='admin-notes-container'>
                      <label htmlFor="athlete-request-notes">Admin notes:</label>
                      <textarea
                        id="athlete-request-notes"
                        value={athleteRequestNotes}
                        onChange={(e) => setAthleteRequestNotes(e.target.value)}
                        className='admin-notes-textarea'
                      />
                      <button onClick={handleUpdateAthleteRequestNotes} className='admin-update-notes-button'>Update Notes</button>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
            </div>
            <ConfirmationModal
              isOpen={isModalOpen}
              title={modalConfig.title}
              message={modalConfig.message}
              confirmText={modalConfig.confirmText}
              cancelText={modalConfig.cancelText}
              placeholderText={modalConfig.placeholderText}
              showTextarea={modalConfig.showTextarea}
              onConfirm={modalConfig.onConfirm}
              onCancel={() => setIsModalOpen(false)}
              onTextareaChange={(value) => setRejectionNote(value)}
            />
    </div>
  );
};

}

export default AdminPage;