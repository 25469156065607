import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../SupabaseClient';


function SignIn() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useState(null);

    // Authentication & Account  ----------------------------------------------------------------->
    // Fetch session
    useEffect(() => {
        setLoading(false);
        const fetchSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setSession(session);
        };
        fetchSession();
    }, []);
    // Authentication change
    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    // Page redirection  ----------------------------------------------------------------->
    useEffect(() => {
        const pageRedirect = async () => {
            setLoading(true);
            const { data: authUser, error: authError } = await supabase.auth.getUser();
            if (authError || !authUser) {
                console.error('Error fetching user:', authError);
                setLoading(false);
                return;
            }

            const { data: athleteData, error: athleteError } = await supabase
                .from('athletes')
                .select('role')
                .eq('uuid', authUser.user.id)
                .single();

            if (athleteError) {
                console.error('Error fetching athlete data:', athleteError);
                setLoading(false);
                return;
            }

            const userRole = athleteData?.role;

            if (userRole === 'creator' || userRole === 'fan') {
                navigate('/dashboard');
            } else if (userRole === 'admin') {
                navigate('/admin');
            } else {
                console.error('Unknown user role:', userRole);
            }
            setLoading(false);
        };

        if (session) {
            pageRedirect();
        }
    }, [session, navigate]);

  return (
    <div className="dashboard-page-format">
      <div className="contact-form">
        <div className="contact-title">Sign in</div>
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          showLinks={false}
          localization={{
            variables: {
              sign_in: {
                email_label: 'Your email address',
                password_label: 'Your password',
              },
              forgotten_password: {
                button_label: 'Forgot password?',
              },
            },
          }}
        />
        {loading && <div className="loader-ring"><div></div><div></div><div></div><div></div></div>}
      </div>
    </div>
  );
}

export default SignIn;