import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';

function SignUpPage() {
    const [showForm, setShowForm] = useState(false);
    const [signupCode, setSignupCode] = useState('');
    const [athleteProfile, setAthleteProfile] = useState({
        firstName: '',
        lastName: '',
        username: '',
        sport: '',
        position: '',
        achievements: [''],
        bio: '',
        interests: [''],
        instagram: '',
        tiktok: '',
        x: '',
        no_go_topics: '',
        email: '',
        password: '',
        confirmPassword: '',
        profilePic: null,
        coverPics: []
    });

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        if (signupCode === 'INFIELD5013') {
            setShowForm(true);
        } else {
            alert('Invalid signup code');
        }
    };

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setAthleteProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleInterestChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newInterests = [...prevProfile.interests];
            newInterests[index] = value;
            return {
                ...prevProfile,
                interests: newInterests
            };
        });
    };

    const handleAchievementChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newAchievements = [...prevProfile.achievements];
            newAchievements[index] = value;
            return {
                ...prevProfile,
                achievements: newAchievements
            };
        });
    };

    const addInterest = () => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: [...prevProfile.interests, ""]
        }));
    };

    const removeInterest = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: prevProfile.interests.filter((_, i) => i !== index)
        }));
    };

    const addAchievement = () => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: [...prevProfile.achievements, ""]
        }));
    };

    const removeAchievement = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: prevProfile.achievements.filter((_, i) => i !== index)
        }));
    };

    const isFormValid = () => {
        const { username, password, confirmPassword } = athleteProfile;
        const usernameValid = /^[a-z0-9]{1,15}$/.test(username); // Username validation
        const passwordsMatch = password === confirmPassword; // Password confirmation check

        return (
            usernameValid &&
            passwordsMatch
            // Object.values(athleteProfile).every(value => 
            //     (typeof value === 'string' && value.trim() !== '') || 
            //     (Array.isArray(value) && value.length > 0 && value.every(item => item.trim() !== ''))
            // )
        );
    };

    const handleFileChange = (e, type) => {
        const files = Array.from(e.target.files);
        if (type === 'profilePic') {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                profilePic: files[0]
            }));
        } else if (type === 'coverPics') {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                coverPics: files.slice(0, 3) // Limit to 3 cover photos
            }));
        }
    };

    const uploadFiles = async (username) => {
        const profilePicPath = `${username}/profilePicture/${athleteProfile.profilePic.name}`;
        const { error: profilePicError } = await supabase.storage
            .from('userMedia')
            .upload(profilePicPath, athleteProfile.profilePic);

        if (profilePicError) throw profilePicError;

        const { publicURL: profilePicURL } = supabase.storage
            .from('userMedia')
            .getPublicUrl(profilePicPath);

        const coverPicURLs = [];
        for (let i = 0; i < athleteProfile.coverPics.length; i++) {
            const coverPicPath = `${username}/coverPictures/${athleteProfile.coverPics[i].name}`;
            const { error: coverPicError } = await supabase.storage
                .from('userMedia')
                .upload(coverPicPath, athleteProfile.coverPics[i]);

            if (coverPicError) throw coverPicError;

            const { publicURL: coverPicURL } = supabase.storage
                .from('userMedia')
                .getPublicUrl(coverPicPath);

            coverPicURLs.push(coverPicURL);
        }

        return { profilePicURL, coverPicURLs };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            alert('Please fill out all fields correctly');
            return;
        }

        try {
            // Upload files
            const { profilePicURL, coverPicURLs } = await uploadFiles(athleteProfile.username);

            // Create auth user
            const { data: authData, error: authError } = await supabase.auth.signUp({
                email: athleteProfile.email,
                password: athleteProfile.password,
            });

            if (authError) throw authError;

            // Create athlete profile
            const { error: profileError } = await supabase
                .from('athletes')
                .insert({
                    uuid: authData.user.id,
                    firstName: athleteProfile.firstName,
                    lastName: athleteProfile.lastName,
                    username: athleteProfile.username,
                    sport: athleteProfile.sport,
                    position: athleteProfile.position,
                    achievements: athleteProfile.achievements,
                    bio: athleteProfile.bio,
                    interests: athleteProfile.interests,
                    instagram: athleteProfile.instagram,
                    tiktok: athleteProfile.tiktok,
                    x: athleteProfile.x,
                    no_go_topics: athleteProfile.no_go_topics,
                    role: 'athlete',
                    profile_pic: profilePicURL,
                    cover_pics: coverPicURLs
                });

            if (profileError) throw profileError;

            alert('Signup successful!');
            // Redirect or perform any other action after successful signup
        } catch (error) {
            console.error('Error during signup:', error);
            alert('An error occurred during signup');
        }
    };

    return (
        <div className="signup-container">
            {!showForm ? (
                <Form onSubmit={handleCodeSubmit} className="signup-code-form">
                    <Form.Group>
                        <Form.Label>Enter Signup Code</Form.Label>
                        <Form.Control
                            type="text"
                            value={signupCode}
                            onChange={(e) => setSignupCode(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button type="submit" className='dashboard-contact-button'>Submit Code</Button>
                </Form>
            ) : (
                <Form onSubmit={handleSubmit} className="signup-form">
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={athleteProfile.firstName}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={athleteProfile.lastName}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            value={athleteProfile.username}
                            onChange={handleProfileChange}
                            required
                            maxLength={15} // Limit to 15 characters
                            pattern="[a-z0-9]*" // Only allow lowercase letters and numbers
                        />
                        <Form.Text className="text-muted">
                            Must be lowercase letters and numbers only, max 15 characters.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Sport</Form.Label>
                        <Form.Control
                            type="text"
                            name="sport"
                            value={athleteProfile.sport}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Position</Form.Label>
                        <Form.Control
                            type="text"
                            name="position"
                            value={athleteProfile.position}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Achievement highlights</Form.Label>
                        {athleteProfile.achievements.map((achievement, index) => (
                            <div key={index} className="achievement-entry">
                                <Form.Control
                                    type="text"
                                    value={achievement}
                                    onChange={(e) => handleAchievementChange(index, e.target.value)}
                                    placeholder="E.g. a stat, championship, record, or affiliation"
                                    required
                                />
                                {athleteProfile.achievements.length > 1 &&
                                    <Button onClick={() => removeAchievement(index)}>Remove</Button>
                                }
                            </div>
                        ))}
                        {athleteProfile.achievements.length < 3 &&
                            <Button onClick={addAchievement}>Add Achievement</Button>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bio</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="5"
                            name="bio"
                            value={athleteProfile.bio}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Areas of insight</Form.Label>
                        {athleteProfile.interests.map((interest, index) => (
                            <div key={index} className="interest-entry">
                                <Form.Control
                                    type="text"
                                    value={interest}
                                    onChange={(e) => handleInterestChange(index, e.target.value)}
                                    placeholder="e.g. Strength training, College Recruiting"
                                    required
                                />
                                {athleteProfile.interests.length > 1 &&
                                    <Button onClick={() => removeInterest(index)}>Remove</Button>
                                }
                            </div>
                        ))}
                        {athleteProfile.interests.length < 8 &&
                            <Button onClick={addInterest}>Add Interest</Button>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                            type="text"
                            name="instagram"
                            value={athleteProfile.instagram}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>TikTok</Form.Label>
                        <Form.Control
                            type="text"
                            name="tiktok"
                            value={athleteProfile.tiktok}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>X/Twitter</Form.Label>
                        <Form.Control
                            type="text"
                            name="x"
                            value={athleteProfile.x}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>No-go topics</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            name="no_go_topics"
                            value={athleteProfile.no_go_topics}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={athleteProfile.email}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={athleteProfile.password}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            value={athleteProfile.confirmPassword}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Profile Picture</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, 'profilePic')}
                            required
                        />
                        {athleteProfile.profilePic && (
                            <img
                                src={URL.createObjectURL(athleteProfile.profilePic)}
                                alt="Profile Thumbnail"
                                style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                            />
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cover Photos (up to 3)</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleFileChange(e, 'coverPics')}
                        />
                        {athleteProfile.coverPics.map((file, index) => (
                            <img
                                key={index}
                                src={URL.createObjectURL(file)}
                                alt={`Cover Thumbnail ${index + 1}`}
                                style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', marginTop: '10px' }}
                            />
                        ))}
                    </Form.Group>
                    <Button type="submit" disabled={!isFormValid()}>Sign Up</Button>
                </Form>
            )}
        </div>
    );
}

export default SignUpPage;