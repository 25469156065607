import { Link } from 'react-router-dom';
import signup from '../signup.jpg';
import signin from '../signin.jpg';
import { BsMoonStars } from "react-icons/bs";
import logo_icon from '../logo_icon.svg';

function AthletePlaceholderPage() {
    return(
        <div className='contact-page-format'>
          <form className="roster-form">
            {/* <img src={logo_icon} alt="Logo" className='roster-logo' /> */}
            <div className="roster-banner">
              <div><BsMoonStars className='roster-icon'/></div>
              <div className='roster-title'>Our Infilders are not accepting new requests at the moment. Check back again soon, and <a className='contact-redirect-link' href='/contact'><b>contact us</b></a> for any questions.</div>
            </div>
            <div className='roster-text'>
              <span className='text-infield-blue'>Fans</span> or others looking to get in touch with Infield can reach us <a className='contact-redirect-link' href='/contact'><b>here</b></a>.
            </div>
            <div className='roster-text'>
              <span className='text-infield-gold'>Infilders</span> choose from one of the following options:
            </div>
            <div className='roster-container'>
              <Link className="roster-entry" to="/signin">
                <div className="roster-entry-image-container">
                    <img className="roster-entry-image" src={signin} alt="signin" />
                    <div className='roster-entry-image-overlay'></div>
                    <div className='roster-entry-info'>
                        <div className='roster-name'>
                            Infielder Signin
                        </div>
                    </div>
                  </div>
              </Link>
              <Link className="roster-entry" to="/join">
                <div className="roster-entry-image-container">
                    <img className="roster-entry-image" src={signup} alt="signup" />
                    <div className='roster-entry-image-overlay'></div>
                    <div className='roster-entry-info'>
                        <div className='roster-name'>
                            Join Waitlist
                        </div>
                    </div>
                  </div>
              </Link>
            </div>
        </form>
      </div>
    );
}

export default AthletePlaceholderPage;