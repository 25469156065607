import React, { useState } from 'react';
import { LuHelpCircle } from "react-icons/lu";
// assets
import dm_integration from '../dm_integration.png';

function HelpTab({ athleteProfile }) {
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const handleFAQClick = (index) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    const faqs = [
        {
            question: "How much time do I have to respond to requests?",
            answer: "Once a request enters your dashboard, you have 3 days to respond. If you don't respond within that time, the request will be automatically rejected, and the sender will be refunded."
        },
        {
            question: "How can I direct Instagram DM requests to my Infield profile?",
            answer: (
                <div>
                    <p>Follow <a style={{fontWeight: "bold", color: "blue"}} target="_blank" href='https://help.instagram.com/561062241952036'>this quick guide</a> to set up an automated message for new DM requests.</p>
                    <p>Copy this text for the Question:</p>
                    <p className='dashboard-faq-copytext'>Hey! I use Infield to answer questions from my followers. Click to get my link!</p>
                    <p>Copy this text for the Automated Response:</p>
                    <p className='dashboard-faq-copytext'>Infield is a platform makes it easy for athletes to get face-to-face with their followers for questions, requests, or to just say hi! Click this link to try it for free, no signup required 👉 https://infieldconnect.col/request/{athleteProfile.username}</p>
                    <p>Finally, verify that your question is show and available</p>
                    <img style={{width: "min(100%,400px)"}} src={dm_integration} />
                </div>
            )
        },
        {
            question: "Can I view past requests and responses",
            answer: "As of now, you can only view requests that are currently in your dashboard. Past requests and responses are not available."
        }
    ];

    return (
        <div className="dashboard-tab-content">
            <div className="dashboard-faq-title">Frequently Asked Questions</div>
            <div className="dashboard-faq-section">
                {faqs.map((faq, index) => (
                    <div key={index} className="dashboard-faq-item">
                        <div className="dashboard-faq-question" onClick={() => handleFAQClick(index)}>
                            {faq.question}
                        </div>
                        <div className={`dashboard-faq-answer ${expandedFAQ === index ? 'expanded' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{marginTop: "20px"}}><b>Need help with something else?</b> Open the feedback messanger in bottom right corner of your screen, and our team will respond as soon as possible.</div>
        </div>
    );
}

export default HelpTab;