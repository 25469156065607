import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import { 
  FacebookShareButton, 
  TwitterShareButton, 
  WhatsappShareButton, 
  LinkedinShareButton, 
  RedditShareButton, 
  FacebookIcon, 
  TwitterIcon, 
  WhatsappIcon, 
  LinkedinIcon, 
  RedditIcon 
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { supabase } from '../SupabaseClient';
import axios from 'axios';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdOutlinePeopleAlt } from "react-icons/md";
import { BsPersonVideo } from "react-icons/bs";
import { RiRefund2Line } from "react-icons/ri";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import ConfirmationAlert from '../components/ConfirmationAlert';

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51Q5LzRKP6FEYUboM7Blb6M2SQdbMitByNwZBWm1B4WDbJfldHoVpPnNzPo5fOQkGvpM8WubFob1IK7quHdIJeR7S00suN7u7gR'); // Switch to live key for production !

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      {props.data.profile_pic && (
        <img
          src={props.data.profile_pic}
          alt={props.data.label}
          style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10, objectFit: "cover", overflowClipMargin: "unset"}}
        />
      )}
      {props.data.label}
    </components.Option>
  );
};

function RequestPage() {
  const { username } = useParams();
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const countries = useMemo(() => countryList().getData(), []);
  const [formData, setFormData] = useState({
    athlete: '',
    athleteID: '',
    city: '',
    countryName: '',
    countryValue: '',
    messageType: '',
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    status: 'new',
    video: null,
  });


  const [athletes, setAthletes] = useState([]);
  const defaultPrice = 0; // (In Cents !!) The price of the request without any donations
  const [discountCode, setDiscountCode] = useState(''); // State for discount code
  const [isDiscountApplied, setIsDiscountApplied] = useState(false); // State for discount application
  const [totalPrice, setTotalPrice] = useState(1500); // Initialize totalPrice with $15.00
  const [selectedDonation, setSelectedDonation] = useState(0); // Initialize selectedDonation
  const [isCustomDonationActive, setIsCustomDonationActive] = useState(false);

  useEffect(() => {
    const fetchAthletes = async () => {
      const { data, error } = await supabase
        .from('athletes')
        .select('*')
        .eq('role', 'creator');
      if (error) {
        console.error('Error fetching athletes:', error);
      } else {
        setAthletes(data);
        if (username) {
          const athleteToSelect = data.find((athlete) => athlete.username === username);
          setSelectedAthlete(athleteToSelect);
          setFormData({
            ...formData,
            athlete: athleteToSelect.username,
            athleteID: athleteToSelect.uuid,
          });
        }
      }
    };
    fetchAthletes();
  }, [username]);


  const athleteOptions = athletes.map((athlete) => ({
    value: athlete.username,
    username: athlete.username,
    label: `${athlete.firstName} ${athlete.lastName}`,
    uuid: athlete.uuid,
    profile_pic: athlete.profile_pic
  }));


  const handleSelectChange = (selectedOption) => {
    setSelectedAthlete(selectedOption);
    setFormData({
      ...formData,
      athlete: selectedOption.value,
      athleteID: selectedOption.uuid,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    setVideoThumbnail(null);
    setFormData({ ...formData, video: e.target.files[0] });
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.onloadedmetadata = () => {
        if (videoElement.duration > 21) {
          alert("The selected video is too long. Please select a video that is 20 seconds or less.");
          clearVideo();
          return;
        } else {
          setFormData({ ...formData, video: file });
          setVideoThumbnail(videoUrl);
        }
      };
    }
  };
  const clearVideo = () => {
    setVideoThumbnail(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };


  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);

  const [message, setMessage] = useState('');
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const calculateRowCount = () => {
    const rowCount = (message.match(/\n/g) || []).length + 1;
    return rowCount < 3 ? 3 : rowCount;
  };


  const uploadVideo = async (video) => {
    const fileName = `${Date.now()}_${video.name}`;
    const { data, error } = await supabase.storage
      .from('videos')
      .upload(`submissions/${fileName}`, video)
    if (error) {
      console.error('Video upload failed:', error);
      return;
    }
    return fileName;
  }
  const getUrl = async (file) => {
    const { data } = supabase
      .storage
      .from('videos')
      .getPublicUrl(`submissions/${file}`)
    return data.publicUrl
  }

  const sendEmail = async (formData) => {
    const emailData = {
      reply_to: 'info@infieldconnect.co',
      requester_email: formData.email,
      requester_name: formData.firstName,
      athlete_name: selectedAthlete.firstName + ' ' + selectedAthlete.lastName,
    };
    try {
      const response = await axios.post('https://infield-backend.vercel.app/request-submission-confirmation', emailData);
      // alert('Payement successful, your request has been submitted!');
      setShowConfirmationAlert(true); 
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const isFormValid = () => {
    const { athlete, subject, messageType, firstName, lastName, city, countryName, email, video, message } = formData;
    const isVideoValid = messageType === 'video' ? video !== null : true;
    const isTextValid = messageType === 'text' ? message !== '' : true;
    return athlete && subject && messageType && firstName && lastName && city && countryName && email && isVideoValid && isTextValid;
  };


  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Fetch the client secret from the server
    axios.post('https://infield-backend.vercel.app/create-payment-intent', {
      amount: totalPrice, // Amount in cents for $15.00
      currency: 'usd'
    })
    .then(response => {
      setClientSecret(response.data.clientSecret);
    })
    .catch(error => {
      console.error('Error fetching client secret:', error);
    });
    setTotalPrice(0);
  }, []);

  const handleDiscountApply = () => {
    if (discountCode.trim() === 'TRYINFIELD') {
      setIsDiscountApplied(true);
      setTotalPrice(0 + selectedDonation);
    } else {
      alert('Invalid discount code.');
    }
  };

  const handleDonation = (amount) => {
    if (selectedDonation === amount) {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    } else {
      setSelectedDonation(amount);
      setTotalPrice((isDiscountApplied ? defaultPrice : defaultPrice) + amount);
    }
    setIsCustomDonationActive(false);
  };

  const toggleCustomDonation = () => {
    setIsCustomDonationActive(!isCustomDonationActive);
    if (isCustomDonationActive) {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    }
  };

  const handleCustomDonationChange = (event) => {
    const customAmount = parseFloat(event.target.value);
    if (customAmount >= 2) {
      setSelectedDonation(customAmount * 100);
      setTotalPrice((isDiscountApplied ? 0 : defaultPrice) + customAmount * 100);
    } else {
      setSelectedDonation(0);
      setTotalPrice(isDiscountApplied ? 0 : defaultPrice);
    }
  };

  return (
    <div className='contact-page-format'>
      {showConfirmationAlert && (
        <ConfirmationAlert
          message="Payment successful, your request has been submitted!"
          onClose={() => setShowConfirmationAlert(false)}
        />
      )}
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <RequestForm
            formData={formData}
            setFormData={setFormData}
            selectedAthlete={selectedAthlete}
            athleteOptions={athleteOptions}
            handleSelectChange={handleSelectChange}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            videoThumbnail={videoThumbnail}
            clearVideo={clearVideo}
            handleMessageChange={handleMessageChange}
            calculateRowCount={calculateRowCount}
            isFormValid={isFormValid}
            submitted={submitted}
            setSubmitted={setSubmitted}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            sendEmail={sendEmail}
            uploadVideo={uploadVideo}
            getUrl={getUrl}
            handleCopy={handleCopy}
            copied={copied}
            countries={countries} // Pass countries as a prop
            totalPrice={totalPrice}
            handleDiscountApply={handleDiscountApply}
            selectedDonation={selectedDonation}
            handleDonation={handleDonation}
            isCustomDonationActive={isCustomDonationActive}
            toggleCustomDonation={toggleCustomDonation}
            handleCustomDonationChange={handleCustomDonationChange}
          />
        </Elements>
      )}
    </div>
  );
}


function RequestForm({
  formData,
  setFormData,
  selectedAthlete,
  athleteOptions,
  handleSelectChange,
  handleChange,
  handleFileChange,
  videoThumbnail,
  clearVideo,
  handleMessageChange,
  calculateRowCount,
  isFormValid,
  submitted,
  setSubmitted,
  isSubmitting,
  setIsSubmitting,
  sendEmail,
  uploadVideo,
  getUrl,
  handleCopy,
  copied,
  countries, // Receive countries as a prop
  totalPrice,
  handleDiscountApply,
  selectedDonation,
  handleDonation,
  isCustomDonationActive,
  toggleCustomDonation,
  handleCustomDonationChange
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true); // Define isCollapsed state
  const fileInputRef = useRef(null); // Define fileInputRef
  const [discountCode, setDiscountCode] = useState('');
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    const athleteEntries = document.querySelectorAll('.request-instructions-container');
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          entry.target.style.animationDelay = `${index * 0.3}s`;
          entry.target.classList.add('fade-in');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });
    athleteEntries.forEach(entry => {
      observer.observe(entry);
    });
    return () => {
      athleteEntries.forEach(entry => {
        observer.unobserve(entry);
      });
    };
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handlePaymentVerification = async () => {
    if (!stripe || !elements) {
      console.error('Stripe or elements not initialized');
      return;
    }
    console.log('Starting payment verification');
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // You can omit the return_url if you don't want to redirect
      },
      redirect: 'if_required',
    });
  
    if (error) {
      console.error('Payment verification failed:', error);
      alert('Payment was unsuccessful. Please try again');
      setIsSubmitting(false);
    } else {
      console.log('Payment verification succeeded');
      setIsPaymentVerified(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!isDiscountApplied && selectedDonation > 0) {
      if (!stripe || !elements) {
        setPaymentError('Stripe has not been initialized properly.');
        setIsSubmitting(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // You can omit the return_url if you don't want to redirect
        },
        redirect: 'if_required',
      });

      if (error) {
        console.error('Payment verification failed:', error);
        setPaymentError(error.message || 'An unknown error occurred during payment verification.');
        setIsSubmitting(false);
        return;
      }

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log('Payment verification succeeded');
        setIsPaymentVerified(true);
      }
    }

    // Proceed with form submission logic
    try {
      // Example: Upload video if necessary
      let videoUrl = null;
      if (formData.messageType === 'video' && formData.video) {
        const filePath = await uploadVideo(formData.video);
        videoUrl = await getUrl(filePath);
        formData.video = videoUrl;
      }

      // Example: Save form data to database
      const { error: dbError } = await supabase
        .from('submissions')
        .insert([{ ...formData }]);
      if (dbError) {
        console.error('Error saving to database:', dbError);
        setIsSubmitting(false);
        return;
      }

      // Example: Send confirmation email
      await sendEmail(formData);

      console.log('Form submitted successfully');
      setSubmitted(true);
    } catch (submissionError) {
      console.error('Form submission failed:', submissionError);
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className='contact-page-format'>
      {!submitted && (
        <form className="request-form" onSubmit={handleSubmit}>
          <div className="request-header">Make a Request!</div>
          <div className="request-title" onClick={toggleCollapse} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            How Infield works
            {isCollapsed ? <AiOutlineDown style={{ marginLeft: '8px' }} /> : <AiOutlineUp style={{ marginLeft: '8px' }} />}
          </div>
          <div className={`request-collapsible-content ${isCollapsed ? 'request-collapsed' : 'request-expanded'}`}>
            <div className="request-instructions-container">
              <div><MdOutlinePeopleAlt className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Get face-to-face</b> with an Infielder by asking them a question in the form of video or text. Ask them about their training, lifestyle, or even just say hi! <b className="text-color-primary">No signup required.</b>
              </div>
            </div>
            <div className="request-instructions-container">
              <div><BsPersonVideo className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Receive a personalized video response</b> from the Infielder within 3 days if your request is approved.
              </div>
            </div>
            <div className="request-instructions-container">
              <div><RiRefund2Line className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Get an immediate refund</b> if your request doesn't get approval or a response.
              </div>
            </div>
            <div className="request-instructions-container">
              <div><AiOutlineSafetyCertificate className="request-instructions-icon" /></div>
              <div className="request-instructions">
                <b className="text-color-primary">Keep it sincere.</b> We auto-reject requests that are inappropriate, hateful, or spam.
              </div>
            </div>
          </div>
          <div className="request-title">1 - Choose an Infielder</div>
          <div className="request-athlete-container">
            <Select
              className="request-athlete-dropdown"
              value={athleteOptions.find(option => option.value === formData.athlete)}
              onChange={handleSelectChange}
              options={athleteOptions}
              placeholder="Select an athlete"
              components={{ Option: CustomOption }}
            />
            {selectedAthlete && (
              <Link to={`/athletes/${selectedAthlete.username}`} style={{ display: "flex", alignItems: "center" }}>
                <img
                  className="request-profile-picture"
                  src={selectedAthlete.profile_pic}
                  alt={`${selectedAthlete.value}`}
                />
                <b style={{ marginLeft: "10px" }}>{selectedAthlete.username}</b>
              </Link>
            )}
          </div>
          <div className="request-title">2 - Create your request</div>
          <div className='contact-info-header'>Request Subject</div>
          <input className='contact-info' name="subject" value={formData.subject} type="text" maxLength="50" required placeholder=" Description of your request" onChange={handleChange} />
          <div className="request-type-parent">
            <label>
              <input
                type="radio"
                name="messageType"
                value="video"
                checked={formData.messageType === 'video'}
                onChange={() => setFormData({ ...formData, messageType: 'video', message: '' })}
                style={{ marginRight: '8px' }}
              />
              <div className="request-type-video"><b>Upload or record a video</b><div className="text-color-gray"> (20 seconds max)</div></div>
            </label>
            <label>
              <input
                type="radio"
                name="messageType"
                value="text"
                checked={formData.messageType === 'text'}
                onChange={() => {
                  setFormData({ ...formData, messageType: 'text', message: '' });
                  clearVideo();
                }}
                style={{ marginRight: '8px' }}
              />
              <div className="request-type-text"><b>Write a message</b></div>
            </label>
          </div>
          {formData.messageType === 'text' &&
            <textarea
              className='contact-message'
              value={formData.message}
              required
              spellCheck="true"
              maxLength="500"
              name="message"
              placeholder="500 characters max"
              ref={fileInputRef}
              onChange={handleMessageChange}
              rows={calculateRowCount()}
            />
          }
          {formData.messageType === 'video' && <div className="request-video-upload">
            <div className="request-subtitle">Upload a video from your device</div>
            <input
              type="file"
              name="video"
              required
              accept="video/*"
              className="request-video-button"
              id="file"
              onChange={handleFileChange} />
            <label htmlFor="file">{videoThumbnail ? "Change video" : "Upload video"}</label>
            {videoThumbnail && (
              <video
                width="200"
                controls
                className="request-video-thumbnail"
              >
                <source src={videoThumbnail} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>}
          <div className="request-title">3 - Enter your contact info</div>
          <div className='contact-info-header'>First name</div>
          <input className='contact-info' name="firstName" value={formData.firstName} key="firstName" type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>Last name</div>
          <input className='contact-info' name="lastName" value={formData.lastName} type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>City</div>
          <input className='contact-info' name="city" value={formData.city} type="text" maxLength="35" required onChange={handleChange} />
          <div className='contact-info-header'>Country</div>
          <Select
            className="request-country-dropdown"
            name="country"
            required
            options={countries}
            value={formData.country}
            onChange={(value) => {
              setFormData({
                ...formData,
                countryName: value.label,
                countryValue: value.value,
              });
            }}
          />
          <div className='contact-info-header'>Email address</div>
          <div style={{ color: "gray", marginBottom: "5px" }}>We will send the athlete's response to this email address</div>
          <input className='contact-info' name="email" value={formData.email} type="email" required maxLength="35" onChange={handleChange} />
          <div className="request-title">4 - Pay and Submit</div>
          {/* <div className="discount-container">
            <input
              type="text"
              className="discount-input"
              placeholder="Enter discount code"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button type="button" className="discount-button" onClick={handleDiscountApply}>
              Apply
            </button>
          </div> */}
          {isDiscountApplied && <div className="discount-message">Discount applied!</div>}

          <div className="donation-container">
            <div>Send them something for their time</div>
            <div className="donation-subcontainer">
              <button
                type="button"
                className={`donation-button ${selectedDonation === 500 ? 'selected' : ''}`}
                onClick={() => handleDonation(500)}
              >
                $5
              </button>
              <button
                type="button"
                className={`donation-button ${selectedDonation === 1000 ? 'selected' : ''}`}
                onClick={() => handleDonation(1000)}
              >
                $10
              </button>
              <button
                type="button"
                className={`donation-button ${isCustomDonationActive ? 'selected' : ''}`}
                onClick={toggleCustomDonation}
              >
                Custom
              </button>
              {isCustomDonationActive && (
                <input
                  type="number"
                  min="1"
                  placeholder="Enter amount"
                  className="donation-input"
                  onChange={handleCustomDonationChange}
                />
              )}
            </div>
          </div>

          {!isDiscountApplied && totalPrice > 0 && (
            <>
              <PaymentElement
                onChange={(event) => {
                  setIsPaymentValid(event.complete);
                  if (event.error) {
                    setPaymentError(event.error.message);
                  } else {
                    setPaymentError('');
                  }
                }}
              />
              {paymentError && <div className="error-message">{paymentError}</div>}
            </>
          )}
          <div className="price-display">
            <span>Order total</span>
            {/* <span>{totalPrice === 0 ? 'FREE' : `$${(totalPrice / 100).toFixed(2)}`}</span> */}
            <span>{`$${(totalPrice / 100).toFixed(2)}`}</span>
          </div>
          <ReCAPTCHA
            sitekey="6LeBkigqAAAAAEDBIpSKa5W1cZDkNBdI3GNc9Ztf"
            onChange={() => setIsCaptchaCompleted(true)}
            onExpired={() => setIsCaptchaCompleted(false)}
          />
          <button
            className="request-button"
            type="submit"
            disabled={!isFormValid() || isSubmitting || (!isDiscountApplied && !isPaymentValid && totalPrice != 0) || !isCaptchaCompleted}
          >
            {isSubmitting ? (
              <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                Submitting
                <div className="loader-button" style={{ marginLeft: '10px' }}>
                </div>
              </div>
            ) : (
              "Submit request"
            )}
          </button>
        </form>
      )}
      {submitted && (<div className="contact-form">
        <div className='request-title'>
          Thank you for your request!
        </div>
        <div className='contact-received-text'>
          If you don't see a confirmation email in your inbox, please check your spam folder. If your request is approved, the athlete will respond within 3 days, or you will receive a full refund.
        </div>
        <div className='contact-received-text' style={{ marginTop: "10px" }}>
          <b>Know someone who might want to ask an Infielder? Share our page!</b>
        </div>
        <div className='share-buttons' style={{ display: 'flex', gap: '10px' }}>
          <FacebookShareButton url="https://www.infieldconnect.co/" quote="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <LinkedinShareButton url="https://www.infieldconnect.co/" title="Check out Infield!" summary="Infield lets fans get face-to-face with their favorite athletes.">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <RedditShareButton url="https://www.infieldconnect.co/" title="Check out Infield! It lets fans get face-to-face with their favorite athletes.">
            <RedditIcon size={32} round />
          </RedditShareButton>
          <CopyToClipboard text="https://www.infieldconnect.co/" onCopy={handleCopy}>
            <button style={{ background: 'none', color: "gray", border: 'none', cursor: 'pointer' }}>
              <FaCopy size={32} />
            </button>
          </CopyToClipboard>
          {copied && <span style={{ color: 'green' }}>Copied!</span>}
        </div>
      </div>)}
    </div>
  );
}

export default RequestPage;